import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import IntlMessages from "../../../services/intlMesseges";
import moment from "moment";
import {
  Form,
  DatePicker,
  Typography,
  Button,
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Table,
  Switch,
  TreeSelect,
  Tag,
  Tooltip,
  Icon,
} from "antd";
import { EditCell, ViewCell } from "../../shared/components/helperCells";
import TextArea from "antd/lib/input/TextArea";
import * as geoFenceAction from "../../../redux/actions/geofence-action";
import * as OrgActions from "../../../redux/actions/organization-actions";
import * as userActions from "../../../redux/actions/travellers-actions";
import * as UsrActions from "../../../redux/actions/user-actions";
import * as alertTypeActions from "../../../redux/actions/alert-types-actions";
import * as travellerActions from "../../../redux/actions/travellers-actions";
import { notifyUser } from "../../../services/notification-service";
import * as Permissions from "../../../services/permissions";
import * as UserService from "../../../services/user-service";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY HH:mm:00";
const dateFormathh = "YYYY-MM-DD HH:mm:ss";
const { RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
function disabledDate(current) {
  // Can not select days before today and today
  return (
    current &&
    current <
      moment()
        .endOf("day")
        .subtract(1, "days")
  );
}

class GeoFenceRules extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      loading: false,
      isShowRulesFrom: false,
      isShowRulesList: true,
      geofencingId: 0,
      fetching: false,
      orgData: [],
      travellerData: [],
      alertTypes: [],
      isDisable: false,
      checkedcommunicatonModes: [],
      defaultSelectedOrgnizations: [],
      defaultSelectedTravellers: [],
      notifyToUsers: [],
      loadMoreNotifyTo: true,
      notifyToPagerCurrent: 1,
      isBothAlertChecked: false,
      sendAlertToTargeted: false,
      stateuser: 1,
      divisionList: [],
      travelerdata: [],
      stateuser2: 1,
      enterr: false,
      exitr: false,
      assignedDivisionsAuto: false,
      expired: false,
      showadd: true,
      orgdiv: false,
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1,
      },
    };
    this.onSearch = this.onSearch.bind(this);
    this.getOrganizationinfo = this.getOrganizationinfo.bind(this);
    this.loadDivisions = this.loadDivisions.bind(this);
    this.treeloadforParentOrganization = this.treeloadforParentOrganization.bind(
      this
    );
    this.disabledRangeTime = this.disabledRangeTime.bind(this);
  }

  getHeaderKeys = () => {
    let currentUser = UserService.getUser();
    return [
      {
        title: "Rule Name",
        dataIndex: "alertTitle",
      },
      {
        title: "Created By",
        render: (text, record) => <span>{record.createdBy}</span>,
      },
      {
        title: "Start Date/Time",
        render: (text, record) => (
          <span>
            {record.startTime
              ? moment(record.startTime).format(dateFormat)
              : ""}
          </span>
        ),
      },

      {
        title: "End Date/Time",
        render: (text, record) => (
          <span>
            {record.endTime ? moment(record.endTime).format(dateFormat) : ""}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.manageOrders.Status" />,
        dataIndex: "isRevoke",
        key: "isRevoke",
        //width: "10%;",
        render: (text, record) =>
          record.isActive === false ? (
            <Tag color="red">
              <IntlMessages id="admin.traveler.inactive" />
            </Tag>
          ) : moment(record.endTime) > moment(new Date()) ? (
            <Tag color="green">
              <IntlMessages id="admin.traveler.active" />
            </Tag>
          ) : (
            <Tag color="red">
              <IntlMessages id="admin.traveler.inactive" />
            </Tag>
          ),
      },
      {
        title: <IntlMessages id="admin.userlisting.action" />,
        rowKey: "action",
        render: (text, record) => (
          <span>
            {record.insertUserId === currentUser.userID ? (
              <EditCell
                onEditCell={() => {
                  this.editItem(record.id, currentUser.userID);
                }}
              />
            ) : (
              <ViewCell
                onViewCell={() => {
                  this.editItem(record.id, currentUser.userID);
                }}
              />
            )}
          </span>
        ),
      },
    ];
  };

  async componentDidMount() {
    await this.props.getUserData();
    var alertOptionsArray = [];
    this.getGeoFenceRules(this.props.match.params.id);
    this.props
      .getAlertTypes({
        filters: {},
        pagination: { page: 1, pageSize: 100 },
        sorter: {},
      })
      .then((resp) => {
        resp.data.map((a, i) =>
          alertOptionsArray.push({ label: a.name, value: a.alertId })
        );

        this.setState({ alertTypes: alertOptionsArray });
      });
    this.setState({ geofencingId: this.props.match.params.id });
    this.props
      .getUserListing({
        orgId: this.props.match.params.orgid,
        filters: { isActiveMembership: true },
      })
      .then((data) => {
        this.setState({ travellerinfo: data.data });
      });
    this.props
      .getActiveInternalUsers({
        orgId: this.props.match.params.orgid,
        filters: {},
      })
      .then((data) => {
        this.setState({ activeUsers: data.data });
      });
    if (this.props.match.params.orgid !== "1") {
      this.loadDivisions(this.props.match.params.orgid);
    }
    // get all organizations
    this.getOrganizationinfo({});
    this.getAlltravellers({ filters: { isActiveMembership: true } });
    this.props.getGeoFenceDataById(this.props.match.params.id).then((resp) => {
      if (resp.data && this.props.userData) {
        this.setState({ UserID: resp.data.insertUserId });
        if (resp.data.insertUserId !== this.props.userData.userID) {
          this.setState({ expired: true });
        }
      }
    });
  }
  disabledRangeTime(a, type) {
    if (type === "start") {
      var depDate = this.props.form.getFieldValue("startTime")
        ? this.props.form.getFieldValue("startTime")[0]
        : "";
      if (depDate) {
        if (
          moment(new Date(depDate._d)).format("MM/DD/YYYY") ===
          moment(new Date()).format("MM/DD/YYYY")
        ) {
          if (depDate._d.getHours() === new Date().getHours()) {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
              disabledMinutes: () => range(0, new Date().getMinutes()),
              disabledSeconds: () => range(0, new Date().getSeconds()),
            };
          } else {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
            };
          }
        } else {
          return {};
        }
      } else {
        return {
          disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
          disabledMinutes: () => range(0, new Date().getMinutes()),
          disabledSeconds: () => range(0, new Date().getSeconds()),
        };
      }
    }
  }
  isEnterchange = (e) => {
    this.setState({ enterr: e.target.checked });
    this.props.form.setFieldsValue({ enterMessage: "" });
  };
  isExitchange = (e) => {
    this.setState({ exitr: e.target.checked });
    this.props.form.setFieldsValue({ exitMessage: "" });
  };
  getAlltravellers(params, check) {
    this.props.getTravellersListing(params).then((data) => {
      let travellerinfo = [{ userId: "all", firstName: "All", lastName: "" }];
      if (data.data) {
        this.setState({ travelerdata: travellerinfo.concat(data.data) });
      }
    });
  }
  getOrganizationinfo(params, loadother) {
    this.props.getOrganizations(params).then((data) => {
      let orgData = [{ organizationId: 0, name: "All" }];

      if (loadother === true) {
        let _parentOrg = [];
        if (data.data.length > 0) {
          _parentOrg = this.state.parentOrganization;

          this.setState({ parentOrganization: _parentOrg.concat(data.data) });
        }
      } else {
        this.setState({ parentOrganization: orgData.concat(data.data) });
      }
    });
  }
  editItem = (id, userid) => {
    this.props.getGeoFenceRule(id).then((resp) => {
      if (resp.data) {
        this.getSelectedOrgAndTravllers(resp.data.alertRecipients);
        this.getSelectedOrgAndTravllersOther(resp.data.targetRecipients);

        this.setState({
          geoFenceRule: resp.data,
          isShowRulesFrom: true,
          isShowRulesList: false,
          enterr: resp.data.isEnter,
          exitr: resp.data.isExit,
          hidefields: resp.data.isInstant,
          showadd: false,
          rangedate: [
            moment(resp.data.startTime, "YYYY-MM-DD HH:mm:ss"),
            moment(resp.data.endTime, "YYYY-MM-DD HH:mm:ss"),
          ],
        });
        if (resp.data.parentOrganizationId !== 0) {
          this.setState({
            parentOrganizationId: {
              key: resp.data.parentOrganizationId,
              label: resp.data.parentOrganizationName,
            },
          });
        } else {
          if (
            resp.data.alertRecipients.filter(
              (data) => data.recipientOrgId === 0 && data.recipientUserId === 0
            ).length > 0 ||
            resp.data.alertRecipients.filter(
              (data) => data.recipientOrgId === 0 && data.recipientUserId === -1
            ).length > 0
          ) {
            this.setState({
              parentOrganizationId: { key: 0, label: "All" },
            });
          } else {
            this.setState({
              parentOrganizationId: undefined,
            });
          }
        }
        if (
          new Date(resp.data.endTime) < new Date() ||
          resp.data.insertUserId !== userid
        ) {
          this.setState({
            expired: true,
          });
        } else {
          this.setState({
            expired: false,
          });
        }
        if (this.props.userData.role === "Administrator") {
          this.loadDivisions(
            resp.data.parentOrganizationId && resp.data.parentOrganizationId > 0
              ? resp.data.parentOrganizationId
              : 1
          );
        } else {
          this.loadDivisions(this.props.match.params.orgid);
        }
        if (resp.data && resp.data.alertRecipients.length > 0) {
          var _dataRecep = [];
          for (var i = 0; i < resp.data.alertRecipients.length; i++) {
            if (
              parseInt(resp.data.alertRecipients[i].recipientUserId) === 0 &&
              resp.data.alertRecipients[i].recipientOrgId !== -1 &&
              resp.data.alertRecipients[i].recipientOrgId !== 0
            ) {
              //if (parseInt(resp.data.alertRecipients[i].recipientOrgId) !== parseInt(this.props.match.params.orgid)) {
              _dataRecep.push({
                label: resp.data.alertRecipients[i].organizationName,
                value: resp.data.alertRecipients[i].recipientOrgId,
                key: resp.data.alertRecipients[i].recipientOrgId,
              });
              //}
            }
          }
          this.setState({ multipleOrganizations: _dataRecep });
        }
      }
    });
  };
  addItem = () => {
    this.setState({
      isShowRulesFrom: true,
      isShowRulesList: false,
      geoFenceRule: [],
      hidefields: false,
      disableNonInstantcase: false,
      disableinstantcase: false,
      orgnization: undefined,
      traveller: undefined,
      parent_orgnization: undefined,
      multipleOrganizations: undefined,
      parentOrganizationId: undefined,
      travellerdata: undefined,
      isBothAlertChecked: false,
      defaultSelectedTravellers: [],
      enterr: false,
      exitr: false,
      instantr: false,
      defaultSelectedOtherTravellers: [],
      isDisable: false,
      expired: false,
      showadd: true,
      defaultSelectedOrgnizations: undefined,
      rangedate: [],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var travellerDataInfo = this.state.defaultSelectedTravellers;
    this.props.form.validateFieldsAndScroll((err, values) => {
      var _target = [];
      _target = this.state.defaultSelectedOtherTravellers;
      if (!err) {
        if (this.state.enterr === true || this.state.exitr === true) {
          Object.keys(values).map(function(key, _idx) {
            if (values[key] == null) {
              values[key] = "";
            } else {
              if (typeof values[key] == "string") {
                values[key] = values[key].trim();
              }
            }
          });
          var finalAlertRecipientsArray = [];
          var finalTargetRecipientsArray = [];
          this.setState({ loading: true });
          values.interstedGeoId = this.state.geofencingId;
          if (_target && _target.length > 0) {
            let allExists = _target.findIndex((i) => i.userId === "all");
            if (allExists > -1) {
              finalTargetRecipientsArray.push({
                recipientOrgId: parseInt(this.props.match.params.orgid),
                recipientUserId: 0,
              });
            } else {
              _target.map(function(el) {
                if (
                  !finalTargetRecipientsArray
                    .map(function(data) {
                      return data.companyId;
                    })
                    .includes(el.companyId)
                ) {
                  finalTargetRecipientsArray.push({
                    recipientOrgId: el.companyId,
                    recipientUserId: el.userId,
                  });
                }
              });
            }
          }

          if (
            (values.travellerdata === undefined ||
              values.travellerdata.length === 0) &&
            this.props.userData &&
            (Permissions.isRole("CSR") || Permissions.isRole("Administrator"))
          ) {
            travellerDataInfo = [];
          } else if (
            (values.traveller === undefined || values.traveller.length === 0) &&
            this.props.userData &&
            (Permissions.isRole("Travel Manager") ||
              Permissions.isRole("Admin") ||
              Permissions.isRole("Division Manager"))
          ) {
            travellerDataInfo = [];
          }
          if (
            (values.parent_orgnization &&
              (values.parent_orgnization.key === 0 ||
                values.parent_orgnization.key === "all") &&
              travellerDataInfo &&
              (travellerDataInfo
                .map(function(data) {
                  return data.key;
                })
                .includes("all") ||
                travellerDataInfo
                  .map(function(data) {
                    return data.userId;
                  })
                  .includes("all") ||
                travellerDataInfo
                  .map(function(data) {
                    return data.userId;
                  })
                  .includes(0))) ||
            values.bothAlertRecipients ||
            this.state.isBothAlertChecked
          ) {
            finalAlertRecipientsArray.push({
              recipientOrgId: 0,
              recipientUserId: 0,
            });
          } else {
            if (
              values.parent_orgnization &&
              (values.parent_orgnization.key === 0 ||
                values.parent_orgnization.key === "all")
            ) {
              finalAlertRecipientsArray.push({
                recipientOrgId: 0,
                recipientUserId: -1,
              });
            } else {
              if (values.multipleOrganizations) {
                values.multipleOrganizations.map((o, i) =>
                  finalAlertRecipientsArray.push({
                    recipientOrgId: o.value,
                    recipientUserId: 0,
                  })
                );
              }
            }

            if (
              travellerDataInfo &&
              (travellerDataInfo
                .map(function(data) {
                  return data.key;
                })
                .includes("all") ||
                travellerDataInfo
                  .map(function(data) {
                    return data.userId;
                  })
                  .includes(0) ||
                travellerDataInfo
                  .map(function(data) {
                    return data.userId;
                  })
                  .includes("all"))
            ) {
              finalAlertRecipientsArray.push({
                recipientOrgId: -1,
                recipientUserId: 0,
              });
            } else {
              travellerDataInfo.map((o, i) =>
                finalAlertRecipientsArray.push({
                  recipientOrgId: o.companyId,
                  recipientUserId: o.userId,
                })
              );
            }
          }
          values["endTime"] = moment(values.startTime[1]).format(dateFormathh);
          values["startTime"] = moment(values.startTime[0]).format(
            dateFormathh
          );

          values["isEnter"] = this.state.enterr;
          values["isExit"] = this.state.exitr;
          if (values.notifyTo && values.notifyTo.length > 0) {
            let _ifNotfyToAll = values.notifyTo.findIndex(
              (el) => el.key === "all"
            );
            if (_ifNotfyToAll > -1) {
              finalTargetRecipientsArray.push({
                recipientOrgId: this.props.userData.companyID,
                recipientUserId: 0,
              });
            } else {
              values.notifyTo.map((o, _idx) =>
                finalTargetRecipientsArray.push({
                  recipientOrgId: this.props.userData.companyID,
                  recipientUserId: o.key,
                })
              );
            }
          }

          let _arrayALert = [];

          if (
            finalTargetRecipientsArray &&
            finalTargetRecipientsArray.length > 0
          ) {
            _arrayALert = this.state.defaultSelectedOtherTravellers.concat(
              finalTargetRecipientsArray
            );
          } else {
            _arrayALert = this.state.defaultSelectedOtherTravellers;
          }
          if (
            this.state.defaultSelectedOtherTravellers &&
            this.state.defaultSelectedOtherTravellers.length > 0
          ) {
            values[
              "targetRecipients"
            ] = this.state.defaultSelectedOtherTravellers.map(function(data) {
              return {
                recipientOrgId: data.recipientOrgId,
                recipientUserId: data.recipientUserId,
              };
            });
          } else {
            values["targetRecipients"] = [];
          }
          values["alertRecipients"] = finalAlertRecipientsArray;
          values["sendAlertToTargeted"] = this.state.sendAlertToTargeted;
          values["selfOpted"] = this.state.geoFenceRule.selfOpted;
          if (
            (this.props.userData && this.props.userData.role === "Administrator"
              ? values["alertRecipients"] &&
                values["alertRecipients"].length > 0 &&
                ((values["multipleOrganizations"] &&
                  values["multipleOrganizations"].length > 0) ||
                  values["parent_orgnization"].key === 0 ||
                  values["parent_orgnization"].key === "all")
              : values["alertRecipients"] &&
                values["alertRecipients"].length > 0) ||
            values["travellerdata"].length > 0 ||
            values.isBothAlertChecked ||
            this.state.isBothAlertChecked
          ) {
            if (this.state.geoFenceRule.id && this.state.geoFenceRule.id > 0) {
              values["id"] = this.state.geoFenceRule.id;
              values["isActive"] = this.state.geoFenceRule.isActive;

              this.submitFormEdit(values);
            } else {
              values["isActive"] = true;
              this.submitFormAdd(values);
            }
          } else {
            {
              notifyUser("Please select at least 1 Target", "error");
            }
          }
        } else {
          notifyUser("Please select at least 1 criteria", "error");
        }
      }
    });
  };

  submitFormAdd = (data) => {
    this.props
      .addGeoFenceRule(data)
      .then((res) => {
        if (res.data.message === "InterstedGeo Alert added successfully.") {
          notifyUser("Rule has been added successfully.", "success");
          this.getGeoFenceRules(this.state.geofencingId);
          this.props.getGeoFenceDataById(this.props.match.params.id);
          this.setState({
            loading: false,
            isShowRulesList: true,
            isShowRulesFrom: false,
          });
          this.props.form.resetFields();
        } else {
        }
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    this.props
      .updateGeoFenceRule(data)
      .then((resp) => {
        if (
          resp.data &&
          resp.data.message === "InterstedGeo Alert updated successfully."
        ) {
          notifyUser("Rule has been updated successfully.", "success");
          this.getGeoFenceRules(this.state.geofencingId);
          this.setState({
            loading: false,
            isShowRulesList: true,
            isShowRulesFrom: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getGeoFenceRules(id) {
    var data = {
      filters: {},
      pagination: { page: 1, pageSize: 100 },
      sorter: {},
    };
    if (id > 0) {
      this.props.getGeoFenceRulesByGeoFenceId(id, data).then((resp) => {
        this.setState({ geoFenceRules: resp.data });
      });
    }
  }

  getSelectedOrgAndTravllers(alertRecipients) {
    var selectedOrgnizationsArray = [];
    var selectedTravellersArray = [];
    if (alertRecipients.length > 0) {
      alertRecipients.map((r) => {
        if (r.recipientOrgId === 0 && r.recipientUserId === 0) {
          selectedTravellersArray.push({
            key: "all",
            label: "All",
          });
          this.setState({
            isDisable: true,
            isBothAlertChecked: true,
          });
        } else {
          if (r.recipientOrgId === 0 && r.recipientUserId === -1) {
            selectedOrgnizationsArray.push({
              key: "all",
              label: "All",
            });
          }
          if (r.recipientOrgId === -1 && r.recipientUserId === 0) {
            selectedTravellersArray.push({
              key: "all",
              label: "All",
            });
          }

          if (
            r.recipientOrgId !== 1 &&
            // r.recipientOrgId !== parseInt(this.props.match.params.orgid) &&
            r.recipientUserId === 0
          ) {
            if (
              !selectedOrgnizationsArray
                .map((info) => {
                  return info.key;
                })
                .includes(r.recipientOrgId)
            ) {
              if (r.organizationName !== null) {
                selectedOrgnizationsArray.push({
                  value: r.recipientOrgId,
                  label: r.organizationName,
                });
              }
            }
          }
          if (r.recipientOrgId > 0 && r.recipientUserId !== 0) {
            if (
              !selectedTravellersArray
                .map((info) => {
                  return info.key;
                })
                .includes(r.recipientUserId)
            ) {
              selectedTravellersArray.push({
                key: r.recipientUserId,
                label: r.employeeName + " " + "<" + r.employeeEmailId + ">",
                companyId: r.recipientOrgId,
                userId: r.recipientUserId,
              });
            }
          }
          this.setState({
            isBothAlertChecked: false,
          });
        }
      });
    }

    this.setState({
      defaultSelectedOrgnizations: selectedOrgnizationsArray,
      defaultSelectedTravellers: selectedTravellersArray,
    });
  }
  getSelectedOrgAndTravllersOther(alertRecipients) {
    var selectedTravellersArray = [];
    if (alertRecipients.length > 0) {
      alertRecipients.map((r) => {
        if (/* r.recipientOrgId > 0 &&  */ r.recipientUserId !== 0) {
          if (
            !selectedTravellersArray
              .map((info) => {
                return info.key;
              })
              .includes(r.recipientUserId)
          ) {
            selectedTravellersArray.push({
              key: r.recipientUserId,
              label: r.employeeName + " " + "<" + r.employeeEmailId + ">",
              companyId: r.recipientOrgId,
              userId: r.recipientUserId,
              recipientOrgId: r.recipientOrgId,
              recipientUserId: r.recipientUserId,
            });
          }
        }
      });
    }
    this.setState({
      defaultSelectedOtherTravellers: selectedTravellersArray,
    });
  }

  fetchOrgnizations = (value) => {
    this.setState({ fetching: true });
    this.props
      .getOrganizations({
        filters: { companyName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          orgData: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
    //this.setState({ orgData: [], fetching: true });
  };

  fetchTravellers = (value) => {
    this.setState({ fetching: true });
    this.props
      .getTravellersListing({
        orgId: 1,
        filters: { firstName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        if (
          value.toLowerCase().includes("a", "al", "all") &&
          value.length < 4
        ) {
          this.setState({
            travelerdata: [
              { userId: "all", firstName: "All", lastName: "" },
            ].concat(resp.data),
            fetching: false,
          });
        } else {
          this.setState({
            fetching: false,
            travelerdata: resp.data,
          });
        }
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
    //this.setState({ orgData: [], fetching: true });
  };

  onAlertCheckboxChange = (e) => {
    if (e.target.checked) {
      this.props.form.setFieldsValue({
        orgnization: undefined,
        traveller: undefined,
        parent_orgnization: { key: "all", label: "All" },
        multipleOrganizations: undefined,
        travellerdata: [{ key: "all", firstName: "All", lastName: "" }],
      });
    } else {
      this.props.form.setFieldsValue({
        orgnization: undefined,
        traveller: undefined,
        parent_orgnization: undefined,
        multipleOrganizations: undefined,
        travellerdata: undefined,
      });
      this.setState({
        defaultSelectedTravellers: [],
      });
    }

    this.setState({
      isDisable: e.target.checked,
      isBothAlertChecked: e.target.checked,
    });
  };

  onNotifyToSearch = (value) => {
    this.setState({ fetching: true });
    this.props
      .getUserListing({
        orgId: this.props.userData.companyID,
        filters: { firstName: value, isActiveMembership: true },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          notifyToUsers: resp.data,
        });
      })
      .catch((_ex) => {
        this.setState({ fetching: false });
      });
  };

  loadotherUsers = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;

      this.props
        .getUserListing({
          orgId: this.props.match.params.orgid,
          filters: { isActiveMembership: true },
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.travellerinfo;
          this.setState({ stateuser: datas });
          this.setState({
            travellerinfo: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };

  loadOtherInactiveUsers = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;

      this.props
        .getUserListing({
          orgId: this.props.match.params.orgid,
          filters: { isActiveMembership: true },
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.activeUsers;
          this.setState({ stateuser: datas });
          this.setState({
            activeUsers: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  loadNotifyTo = (e) => {
    if (
      this.state.loadMoreNotifyTo === true &&
      e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight
    ) {
      var _notifyToUsers = this.state.notifyToUsers;
      var _notifyToPagerCurrent = this.state.notifyToPagerCurrent + 1;
      this.props
        .getUserListing({
          orgId: this.props.userData.companyID,
          filters: { firstName: e.target.value, isActiveMembership: true },
          pagination: { page: _notifyToPagerCurrent, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          if (resp.data.length > 0) {
            resp.data.map(function(item) {
              _notifyToUsers.push(item);
            });
            this.setState({
              notifyToUsers: _notifyToUsers,
              notifyToPagerCurrent: _notifyToPagerCurrent,
            });
          } else {
            this.setState({ loadMoreNotifyTo: false });
          }
        })
        .catch((_ex) => {
          this.setState({ fetching: false });
        });
    }
  };

  changeIsActive = (value) => {
    let _geoFence = this.state.geoFenceRule;
    _geoFence.isActive = value;
    this.setState({ geoFenceRule: _geoFence });
  };

  onChange = (value) => {
    let _geoFence = this.state.geoFenceRule;
    _geoFence.selfOpted = value;
    this.setState({ geoFenceRule: _geoFence });
  };
  onTravelersSelect = (_idx, option) => {
    let assignedUsersList = [];
    if (this.state.defaultSelectedTravellers) {
      assignedUsersList = this.state.defaultSelectedTravellers;
    }
    if (option.key === "all") {
      assignedUsersList.push({
        userId: 0,
        companyId: parseInt(this.props.match.params.orgid),
      });
    } else {
      assignedUsersList.push({
        userId: option.props.value,
        companyId: option.props.companyid,
      });
    }

    this.setState({ defaultSelectedTravellers: assignedUsersList });
  };

  onRemoveTravelers = (uid) => {
    let assignedUsersList = this.state.defaultSelectedTravellers;
    let removedEmployee = this.state.defaultSelectedTravellers.findIndex(
      (e) => e.userId === uid
    );
    assignedUsersList.splice(removedEmployee, 1);
    this.setState({ defaultSelectedTravellers: assignedUsersList });
  };

  onOtherTravelersSelect = (_idx, option) => {
    let assignedUsersList = this.state.defaultSelectedOtherTravellers || [];
    assignedUsersList.push({
      recipientUserId: option.props.value,
      recipientOrgId: option.props.companyid,
    });

    this.setState({ defaultSelectedOtherTravellers: assignedUsersList });
  };

  onOtherRemoveTravelers = (uid) => {
    let assignedUsersList = this.state.defaultSelectedOtherTravellers;
    let removedEmployee = this.state.defaultSelectedOtherTravellers.findIndex(
      (e) => e.userId === uid
    );
    assignedUsersList.splice(removedEmployee, 1);
    this.setState({ defaultSelectedOtherTravellers: assignedUsersList });
  };

  viewGeofenceRulesList() {
    this.setState({
      loading: false,
      isShowRulesList: true,
      isShowRulesFrom: false,
    });
  }
  onChangeAssignedOrganizations = (selected) => {
    let _selected = [];
    if (selected.length > 0) {
      selected.map(function(el) {
        _selected.push({
          branchId: el.value,
        });
      });
    }
    this.setState({ targetOrganizations: _selected });
    this.props.form.setFieldsValue({ multipleOrganizations: undefined });
  };

  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId === parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }

        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };
  loadDivisions = (orgId) => {
    if (orgId !== "all") {
      this.props.getDivisionsList(orgId).then((resp) => {
        let parentId;
        for (var i in resp.data) {
          if (resp.data[i].organizationId === orgId) {
            parentId = resp.data[i].parentOrgId;
          }
        }

        let list = this.unflatten(resp.data);

        this.setState({
          divisionList: list,
        });
      });
    }
  };
  unflatten = (arr) => {
    if (arr !== undefined) {
      var tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;
      for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        arrElem.title = arrElem.name;
        arrElem.value = arrElem.organizationId;
        arrElem.key = arrElem.organizationId;
        mappedArr[arrElem.organizationId] = arrElem;
        mappedArr[arrElem.organizationId]["children"] = [];
      }
      for (var organizationId in mappedArr) {
        if (mappedArr.hasOwnProperty(organizationId)) {
          mappedElem = mappedArr[organizationId];
          if (mappedElem.parentOrgId && mappedArr[mappedElem["parentOrgId"]]) {
            mappedArr[mappedElem["parentOrgId"]] &&
              mappedArr[mappedElem["parentOrgId"]]["children"].push(mappedElem);
          } else {
            tree.push(mappedElem);
          }
        }
      }
      return tree;
    }
  };
  treeloadforParentOrganization(e) {
    this.props.form.setFieldsValue({
      multipleOrganizations: undefined,
    });
    if (e) {
      if (e.key === 0 || e.key === "all") {
        this.setState({ orgdiv: true });
      } else {
        this.setState({ orgdiv: false });
      }
      this.loadDivisions(e.key);
    }
  }
  onSearch(value, type) {
    var params = {
      filters: { companyName: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {},
    };
    this.props.getOrganizations(params).then((data) => {
      if (value.toLowerCase().includes("a", "al", "all") && value.length < 4) {
        this.setState({
          parentOrganization: [{ organizationId: "all", name: "All" }].concat(
            data.data
          ),
        });
      } else {
        this.setState({ parentOrganization: data.data });
      }
    });
  }

  getOthertravelerData = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser2 + 1;
      this.props
        .getTravellersListing({
          orgId: 1,
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.travelerdata;
          this.setState({ stateuser2: datas });
          this.setState({
            travelerdata: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  setAssignedDivisionsAuto = (e) => {
    this.setState({ assignedDivisionsAuto: e.target.checked });
  };
  loadOtherOrganizations = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      this.getOrganizationinfo(
        {
          filters: {},
          pagination: { page: datas, pageSize: 10 },
        },
        true
      );
      this.setState({ stateuser: datas });
    }
  };
  fetchTraveler = (value) => {
    this.props
      .getUserListing({
        orgId: this.props.match.params.orgid,
        filters: { firstName: value, isActiveMembership: true },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((data) => {
        this.setState({ travellerinfo: data.data });
      });
  };

  fetchActiveUsers = (value) => {
    this.props
      .getActiveInternalUsers({
        orgId: this.props.match.params.orgid,
        filters: { firstName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((data) => {
        this.setState({ activeUsers: data.data });
      });
  };

  goBackForm() {
    this.setState({
      isShowRulesFrom: false,
      isShowRulesList: true,
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;

    return (
      <div>
        {this.state.isShowRulesList && (
          <div>
            <Row gutter={24}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <Typography.Title level={4}>
                  Manage Geofences Rules
                </Typography.Title> */}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  type="primary"
                  className="right-fl def-blue"
                  htmlType="button"
                  onClick={() => this.props.history.push("../")}
                  style={{ margin: "0 10px" }}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
                <Button
                  type="primary"
                  onClick={() => this.addItem()}
                  className="right-fl def-blue"
                >
                  <IntlMessages id="admin.userlisting.addnew" />
                </Button>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col>
                <Table
                  columns={this.getHeaderKeys()}
                  rowKey={(record) => record.id}
                  dataSource={this.state.geoFenceRules}
                  loading={this.state.loading}
                  pagination={this.state.pagination}
                />
              </Col>
            </Row>
          </div>
        )}
        {this.state.isShowRulesFrom && (
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {this.state.showadd ? (
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.geofencing.addGeoRule"></IntlMessages>
                    {this.state.geoFenceRules &&
                    this.state.geoFenceRules.length > 0
                      ? " (" + this.state.geoFenceRules[0].interstedGeo + ")"
                      : ""}
                  </Typography.Title>
                ) : (
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.geofencing.editGeoRule"></IntlMessages>
                    {this.state.geoFenceRules &&
                    this.state.geoFenceRules.length > 0
                      ? " (" + this.state.geoFenceRules[0].interstedGeo + ")"
                      : ""}
                  </Typography.Title>
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  type="primary"
                  onClick={() => this.viewGeofenceRulesList()}
                  className="right-fl def-blue"
                >
                  <IntlMessages id="admin.button.back" />
                </Button>
              </Col>
            </Row>
            <hr />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label="Rule Name">
                  {getFieldDecorator("alertTitle", {
                    initialValue:
                      this.state.geoFenceRule.alertTitle === null
                        ? ""
                        : this.state.geoFenceRule.alertTitle,
                    rules: [
                      {
                        whitespace: true,
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                  })(<Input maxLength="90" disabled={this.state.expired} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  {...formItemLayout}
                  label={
                    <IntlMessages id="admin.itinerary.fromtodate"></IntlMessages>
                  }
                >
                  {getFieldDecorator("startTime", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.state.rangedate && this.state.rangedate.length === 2
                        ? this.state.rangedate
                        : this.state.rangedate,
                  })(
                    <RangePicker
                      style={{ width: "100%" }}
                      disabled={this.state.expired}
                      disabledDate={disabledDate}
                      disabledTime={this.disabledRangeTime}
                      showTime={{
                        hideDisabledOptions: true,
                      }}
                      showTime={{
                        minuteStep: 15,
                        secondStep: 60,
                      }}
                      format={dateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item {...formItemLayout} label="Description">
                  {getFieldDecorator("alertDescription", {
                    initialValue:
                      this.state.geoFenceRule.alertDescription === null
                        ? ""
                        : this.state.geoFenceRule.alertDescription,
                  })(
                    <TextArea maxLength="200" disabled={this.state.expired} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography.Title level={3}>
                  <span style={{ color: "red" }}>*</span> Entry/Exit Criteria
                </Typography.Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="When To Send">
                  {getFieldDecorator("isEnter", {
                    initialValue: this.state.geoFenceRule.isEnter,
                  })(
                    <Row>
                      <Col span={8}>
                        <Checkbox
                          disabled={this.state.expired}
                          onChange={this.isEnterchange}
                          defaultChecked={this.state.geoFenceRule.isEnter}
                        >
                          Enter
                        </Checkbox>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={
                    <span>
                      Enter Message&nbsp;
                      <Tooltip title="Message will be sent to the user(s) who is handling the targeted travelers.">
                        <Icon
                          style={{ fontSize: "18px" }}
                          type="question-circle-o"
                        />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("enterMessage", {
                    rules: [
                      {
                        whitespace: true,
                        required: this.state.enterr,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.state.geoFenceRule.enterMessage === null
                        ? ""
                        : this.state.geoFenceRule.enterMessage,
                  })(
                    <Input
                      maxLength="160"
                      disabled={this.state.expired || !this.state.enterr}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item>
                  {getFieldDecorator("isExit", {
                    initialValue: this.state.geoFenceRule.isExit,
                  })(
                    <Row>
                      <Col span={8}>
                        <Checkbox
                          disabled={this.state.expired}
                          onChange={this.isExitchange}
                          defaultChecked={this.state.geoFenceRule.isExit}
                        >
                          Exit
                        </Checkbox>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={
                    <span>
                      Exit Message&nbsp;
                      <Tooltip title="Message will be sent to the user(s) who is handling the targeted travelers.">
                        <Icon
                          style={{ fontSize: "18px" }}
                          type="question-circle-o"
                        />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("exitMessage", {
                    rules: [
                      {
                        whitespace: true,
                        required: this.state.exitr,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.state.geoFenceRule.exitMessage === null
                        ? ""
                        : this.state.geoFenceRule.exitMessage,
                  })(
                    <Input
                      maxLength="160"
                      disabled={this.state.expired || !this.state.exitr}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography.Title level={3}>
                  <span style={{ color: "red" }}>*</span> Targeted For
                </Typography.Title>
              </Col>
            </Row>
            {this.props.userData &&
            (this.props.userData.role === "Administrator" ||
              this.props.userData.role === "CSR") ? (
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item>
                    {getFieldDecorator("bothAlertRecipients", {
                      initialValue: this.state.isBothAlertChecked,
                    })(
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            disabled={this.state.expired}
                            defaultChecked={this.state.isBothAlertChecked}
                            onChange={this.onAlertCheckboxChange}
                          >
                            All
                          </Checkbox>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={
                      <IntlMessages id="admin.organization.division"></IntlMessages>
                    }
                  >
                    {getFieldDecorator("multipleOrganizations", {
                      rules: [
                        {
                          required:
                            !this.state.orgdiv &&
                            this.props.form.getFieldValue(
                              "parent_orgnization"
                            ) &&
                            this.props.form.getFieldValue("parent_orgnization")
                              .key !== 0 &&
                            this.props.form.getFieldValue("parent_orgnization")
                              .key !== "all",
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.defaultSelectedOrgnizations &&
                        this.state.defaultSelectedOrgnizations.length > 0 &&
                        this.state.defaultSelectedOrgnizations !== undefined
                          ? this.state.defaultSelectedOrgnizations
                          : null,
                    })(
                      <TreeSelect
                        disabled={
                          this.state.expired ||
                          this.state.isDisable ||
                          this.state.orgdiv
                        }
                        treeData={this.state.divisionList}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        onChange={this.onChangeAssignedOrganizations}
                        treeCheckable={true}
                        treeCheckStrictly={!this.state.assignedDivisionsAuto}
                        labelInValue={true}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeDefaultExpandAll
                      />
                    )}
                    <Checkbox
                      checked={this.state.assignedDivisionsAuto}
                      onChange={this.setAssignedDivisionsAuto}
                    />{" "}
                    <IntlMessages id="admin.autoselect.childdivisions" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label="Traveler">
                    {getFieldDecorator("traveller", {
                      initialValue: this.state.defaultSelectedTravellers,
                    })(
                      <Select
                        mode="multiple"
                        allowClear
                        disabled={this.state.isDisable || this.state.expired}
                        onPopupScroll={this.loadotherUsers}
                        optionFilterProp="children"
                        filterOption={false}
                        //disabled={this.state.isDisable}
                        labelInValue={true}
                        onSelect={this.onTravelersSelect}
                        onDeselect={this.onRemoveTravelers}
                        onSearch={this.fetchTraveler}
                      >
                        {/* <Option key="all">All</Option> */}
                        {this.state.travellerinfo
                          ? this.state.travellerinfo.map((t) => {
                              return (
                                <Option
                                  key={t.userId}
                                  value={t.userId}
                                  companyid={t.companyId}
                                >
                                  {t.firstName} {t.lastName}{" "}
                                  {"<" + t.userName + ">"}
                                </Option>
                              );
                            })
                          : ""}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {this.props.userData &&
            (this.props.userData.role === "CSR" ||
              this.props.userData.role === "Administrator") ? (
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label="Parent Organization">
                    {getFieldDecorator("parent_orgnization", {
                      initialValue:
                        this.state.parentOrganizationId !== undefined
                          ? this.state.parentOrganizationId
                          : "",
                    })(
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        disabled={this.state.isDisable || this.state.expired}
                        onPopupScroll={this.loadOtherOrganizations}
                        showSearch
                        onChange={this.treeloadforParentOrganization}
                        onSearch={this.onSearch}
                        labelInValue={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.parentOrganization
                          ? this.state.parentOrganization.map(function(item) {
                              return (
                                <Option
                                  key={item.organizationId}
                                  value={item.organizationId}
                                >
                                  {item.name}
                                </Option>
                              );
                            })
                          : ""}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={
                      <IntlMessages id="admin.organization.division"></IntlMessages>
                    }
                  >
                    {getFieldDecorator("multipleOrganizations", {
                      rules: [
                        {
                          required:
                            !this.state.orgdiv &&
                            this.props.form.getFieldValue(
                              "parent_orgnization"
                            ) &&
                            this.props.form.getFieldValue("parent_orgnization")
                              .key !== 0 &&
                            this.props.form.getFieldValue("parent_orgnization")
                              .key !== "all",
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.multipleOrganizations &&
                        this.state.multipleOrganizations.length > 0 &&
                        this.state.multipleOrganizations !== undefined
                          ? this.state.multipleOrganizations
                          : null,
                    })(
                      <TreeSelect
                        allowClear
                        treeData={this.state.divisionList}
                        style={{ width: "100%" }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        onChange={this.onChangeAssignedOrganizations}
                        treeCheckable={true}
                        disabled={
                          this.state.expired ||
                          this.state.isDisable ||
                          this.state.orgdiv
                        }
                        labelInValue={true}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeDefaultExpandAll
                        treeCheckStrictly={!this.state.assignedDivisionsAuto}
                      />
                    )}
                    <Checkbox
                      checked={this.state.assignedDivisionsAuto}
                      disabled={this.state.expire}
                      onChange={this.setAssignedDivisionsAuto}
                    />{" "}
                    <IntlMessages id="admin.autoselect.childdivisions" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <IntlMessages id="admin.manageOrders.travellerNameb2c" />
                    }
                  >
                    {getFieldDecorator("travellerdata", {
                      initialValue: this.state.defaultSelectedTravellers,
                    })(
                      <Select
                        allowClear
                        mode="multiple"
                        filterOption={false}
                        onSearch={this.fetchTravellers}
                        style={{ width: "100%" }}
                        labelInValue={true}
                        disabled={this.state.isDisable || this.state.expired}
                        onPopupScroll={this.getOthertravelerData}
                        onSelect={this.onTravelersSelect}
                        onDeselect={this.onRemoveTravelers}
                        optionFilterProp="children"
                      >
                        {this.state.travelerdata
                          ? this.state.travelerdata.map((t) => {
                              return (
                                <Option
                                  key={t.userId}
                                  value={t.userId}
                                  companyid={1}
                                >
                                  {t.firstName} {t.lastName}{" "}
                                  {t.userName ? "<" + t.userName + ">" : ""}
                                </Option>
                              );
                            })
                          : ""}
                        {/* {this.state.travelerdata ?
                          this.state.travelerdata.map(function (item) {
                            return (
                              <Option key={item.userId} value={item.userId}>

                                {item.firstName} {item.lastName} {item.userName ? '<' + item.userName + '>' : ''}
                              </Option>
                            );
                          })
                          : ''} */}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <br />
            <br />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography.Title level={3}>Notify To</Typography.Title>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                Send Alert to Targeted?
                {
                  <span>
                    &nbsp;
                    <Tooltip title="Enter/Exit message will be sent to the targeted travelers as well.">
                      <Icon
                        style={{ fontSize: "18px" }}
                        type="question-circle-o"
                      />
                    </Tooltip>
                  </span>
                }
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={this.state.expired}
                  checked={
                    this.state.geoFenceRule
                      ? this.state.geoFenceRule.selfOpted
                      : false
                  }
                  onChange={this.onChange}
                />
              </Col>
            </Row>
            <br />
            <br />

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <span>Select additional persons to notify :</span>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item>
                  {getFieldDecorator("notifyTo", {
                    initialValue: this.state.defaultSelectedOtherTravellers,
                  })(
                    <Select
                      mode="multiple"
                      allowClear={true}
                      disabled={this.state.expired}
                      onPopupScroll={this.loadOtherInactiveUsers}
                      optionFilterProp="children"
                      filterOption={false}
                      labelInValue={true}
                      onSelect={this.onOtherTravelersSelect}
                      onDeselect={this.onOtherRemoveTravelers}
                      onSearch={this.fetchActiveUsers}
                    >
                      {this.state.activeUsers
                        ? this.state.activeUsers.map((t) => {
                            return (
                              <Option
                                key={t.userId}
                                value={t.userId}
                                companyid={t.companyId}
                              >
                                {t.firstName} {t.lastName} {"<" + t.email + ">"}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                Status
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Switch
                  checkedChildren="Active"
                  disabled={this.state.expired}
                  unCheckedChildren="Inactive"
                  onChange={this.changeIsActive}
                  checked={
                    this.state.geoFenceRule.isActive === false ||
                    new Date(this.props.form.getFieldValue("endTime")) <
                      new Date()
                      ? false
                      : true
                  }
                  name="status"
                />
              </Col>
            </Row>
            {this.state.expired === false ? (
              <Row gutter={24} style={{ marginTop: "25px" }}>
                <Col xs={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Form>
        )}
      </div>
    );
  }
}

GeoFenceRules.propTypes = {
  getUserListing: PropTypes.func,
};

function mapStateToProps(state) {
  return { ...state.user };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...geoFenceAction,
      ...OrgActions,
      ...userActions,
      ...alertTypeActions,
      ...UsrActions,
      ...travellerActions,
    },
    dispatch
  );
}
const WrappedGeoRulesForm = Form.create({ name: "geo-fence-rules" })(
  GeoFenceRules
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedGeoRulesForm
  )
);
