import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as travellerActions from "../../../redux/actions/travellers-actions";
import OrgAPI from "../../../redux/api/b2b2c-organization-api";
import * as userActions from "../../../redux/actions/user-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import IntlMessages from "../../../services/intlMesseges";
import { EditCell } from "../../shared/components/helperCells";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import * as B2B2COrgActions from "../../../redux/actions/b2b2c-organization-actions";
import moment from "moment";
import axios from "axios";

import { DatePicker } from "antd";
import {
  Switch,
  Modal,
  Spin,
  Popover,
  Popconfirm,
  Table,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Upload,
  message,
  // Divider,
  Tooltip,
  Drawer,
  Form,
  Select,
  Radio,
} from "antd";
import config, { API1 } from "../../../../../config";
import Axios from "axios";
import { notifyUser } from "../../../services/notification-service";
import * as Permission from "../../../services/permissions";
import * as UserServices from "../../../services/user-service";
import AppLocale from "../../../languageProvider";
import OrgTree from "../../shared/components/orgTree";

import * as OrgPlanTypeActions from "../../../redux/actions/org-plan-types";
const { Option } = Select;
const { confirm } = Modal;
const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";

class ManageTravelers extends Component {
  constructor(props) {
    super(props);
    this.module = "travelers";
    this.state = {
      userStatus: [],
      currentPlanToUnassign: "",
      confirmDeactivateEmployeeVisible: false,
      disableAssignButton: false,
      loading: false,
      uploadingEmployees: false,
      organizationProductsInfo: [],
      showAssignPlan: true,
      isBulkUploadModalVisible: false,
      startdate: new Date(),
      enddate: new Date(),
      isSixMonthPlan: false,
      isThisGuardMeOrg: false,
      memberPlansList: [],
      selctedPlanFromList: {},
      bulkUploadInstance: {
        sendEmailForNew: false,
        isAssignMembership: false,
        file: null,
        product: -1,
        uploadFileName: "",
      },
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
      assigningLicense: false,
    };
  }
  getSelectedFilterValue = (index) => {
    return (
      (this.props.paginginfo[this.module] &&
        this.props.paginginfo[this.module].filter &&
        this.props.paginginfo[this.module].filter[index]) ||
      null
    );
  };
  resendMembershipInfo = async (id) => {
    await this.props.resendMembershipInfo(id);
  };

  // download sample csv
  downloadSampleCsv = () => {
    window.location.href = config.API1 + "/SampleCSV/OrganizationUsers.csv";
  };

  csvUploadProps = () => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    let _this = this;
    return {
      name: "file",
      showUploadList: false,
      accept: ".csv",
      beforeUpload: (file) => {
        let ext = file.name.split(".");
        ext = ext.pop();
        if (ext === "csv") {
          let _bulkUploadInstance = _this.state.bulkUploadInstance;
          _bulkUploadInstance.file = file;
          _bulkUploadInstance.uploadFileName = file.name;
          _this.setState({ bulkUploadInstance: _bulkUploadInstance });
        } else {
          message.error(
            currentAppLocale.messages[
              "admin.employees.bulkupload.error.csvRequired"
            ]
          );
          let _bulkUploadInstance = _this.state.bulkUploadInstance;
          _bulkUploadInstance.file = null;
          _bulkUploadInstance.uploadFileName = "";
          _this.setState({ bulkUploadInstance: _bulkUploadInstance });
        }
        return false;
      },
      onStart: (_file) => {
        _this.setState({ uploadingEmployees: true });
      },
    };
  };

  // edit new travelor
  editItem = (id) => {
    this.props.history.push("./" + id + "/");
  };

  onOrgLicenseChange = (_idx, licenseId) => {
    this.setState({
      currentLicense: licenseId.key === "undefined" ? null : licenseId.key,
    });
  };

  onOrgLicenseDeselect = () => {
    return;
  };

  //upload
  cancelBulkUploadEmployees = () => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.product = -1;
    _bulkUploadInstance.file = null;
    _bulkUploadInstance.uploadFileName = "";
    _bulkUploadInstance.isAssignMembership = false;
    _bulkUploadInstance.sendEmailForNew = false;
    this.setState({
      isBulkUploadModalVisible: false,
      bulkUploadInstance: _bulkUploadInstance,
    });
  };

  initBulkUploadEmployees = () => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    let _this = this;
    let file = _this.state.bulkUploadInstance.file;

    if (file === null) {
      message.error(
        currentAppLocale.messages["admin.fileupload.error.chooseFile"]
      );
    } else if (
      _this.state.bulkUploadInstance.isAssignMembership === true &&
      parseInt(_this.state.bulkUploadInstance.product) === -1
    ) {
      message.error(
        currentAppLocale.messages[
          "admin.employees.bulkupload.error.membershipProduct"
        ]
      );
    } else {
      let ext = file.name.split(".");
      ext = ext.pop();
      if (ext === "csv") {
        let token = UserServices.getToken();
        let _headers = { "Content-Type": "multipart/form-data" };
        if (token) {
          _headers["authorization"] = token ? "Bearer " + token : undefined;
        }
        const formData = new FormData();
        formData.append("file", file);
        //formData.append("productId", _this.state.bulkUploadInstance.product);
        //formData.append("assignMembership", _this.state.bulkUploadInstance.isAssignMembership);
        Axios({
          method: "post",
          //url: config.API1 + "/api/BulkUploadUsers/" + _this.props.match.params.id+"/"+_this.state.bulkUploadInstance.product+"/"+_this.state.bulkUploadInstance.isAssignMembership+"/"+_this.state.bulkUploadInstance.sendEmailForNew,
          url:
            config.API1 +
            "/api/BulkUploadTravellers/" +
            _this.props.match.params.id +
            "/" +
            _this.state.bulkUploadInstance.product +
            "/" +
            _this.state.bulkUploadInstance.isAssignMembership +
            "/" +
            _this.state.bulkUploadInstance.sendEmailForNew,
          data: formData,
          headers: _headers,
        })
          .then(function(_response) {
            _this.setState({ uploadingEmployees: false });
            if (_response.errors) {
              notifyUser(
                currentAppLocale.messages["admin.error.fileUploadFailed"],
                "error"
              );
            } else {
              notifyUser(_response.data.data.message, "success");
              _this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
              let _bulkUploadInstance = _this.state.bulkUploadInstance;
              _bulkUploadInstance.product = -1;
              _bulkUploadInstance.file = null;
              _bulkUploadInstance.uploadFileName = "";
              _bulkUploadInstance.isAssignMembership = false;
              _bulkUploadInstance.sendEmailForNew = false;
              _this.setState({
                isBulkUploadModalVisible: false,
                bulkUploadInstance: _bulkUploadInstance,
              });
            }
          })
          .catch(function(_response) {
            if (_response.response && _response.response.data) {
              notifyUser(_response.response.data.Message, "error");
            } else {
              notifyUser(
                currentAppLocale.messages["admin.error.fileUploadFailed"],
                "error"
              );
            }
            _this.setState({ uploadingEmployees: false });
            _this.handleTableChange({ current: 1, pageSize: 100 }, {}, {});
          });
      } else {
        message.error(
          currentAppLocale.messages[
            "admin.employees.bulkupload.error.csvRequired"
          ]
        );
      }
    }
  };

  //Upload csv functions

  onMembershipProductChange = (productId) => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.product = productId;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };

  onChangeSendEmailToggle = (e) => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.sendEmailForNew = e.target.value;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };

  onChangeAssignMembershipToggle = (e) => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.isAssignMembership = e.target.value;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };

  removeBulkUploadFile = () => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.uploadFileName = "";
    _bulkUploadInstance.file = null;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };
  //card plan
  showEditPlanDrawer = async (record) => {
    record.membershipNumbers && record.membershipNumbers.length > 1
      ? this.setState({ disableAssignButton: true })
      : this.setState({ disableAssignButton: false });

    let uid = record.userId;
    let currentUser = this.state.data.find((el) => el.userId === uid);
    let data = {
      currentEmployee: uid,
      editPlanVisible: true,
    };

    // let respn =   await OrgAPI.getTravellerInfo(uid);
    if (currentUser.memberProduct && currentUser.memberProduct.productId) {
      let args = {
        orgId: this.state.currentOrganization,
        productId: currentUser.memberProduct.productId,
        filters: {},
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      };
      let orgLicenses = await OrgAPI.getOrganizationLicensesByProduct(args);
      data.currentProduct = currentUser.memberProduct.productId;
      data.currentProductName = currentUser.memberProduct.productName;
      data.organizationLicenses = orgLicenses.data;
      data.showAssignPlan = false;
    }

    if (currentUser.memberPlans && currentUser.memberPlans.length > 0) {
      data.currentLicense = currentUser.memberPlans[0].licenseId;
      data.currentLicenseNumber = currentUser.memberPlans[0].licenseNumber;
      data.currentLicenseName = currentUser.memberPlans[0].productName;
      data.currentplanStartDate = currentUser.memberPlans[0].planStartDate;

      this.setState({ memberPlansList: currentUser.memberPlans });

      let productData =
        this.state.licenseTotals &&
        this.state.licenseTotals.find((el) => {
          return el.productId == currentUser.memberProduct.productId;
        });

      let planDays = productData && productData.product.substr(-3);

      this.calculateEndDate(planDays, currentUser.memberPlan.planStartDate);

      //  this.setState({startdate:new Date(currentUser.memberPlan.planStartDate)});
    } else {
      data.currentLicense = null;
      data.currentLicenseNumber = "";
      data.currentLicenseName = "";
    }
    this.setState(data);
    
  };

  handleSelectedPlanData = (e) => {
    let planData =
      this.state.memberPlansList &&
      this.state.memberPlansList.find((x) => {
        return x.licenseId == e;
      });
    let planDays = planData.productName.substr(-3);
    this.setState({
      selctedPlanFromList: planData,
      currentplanStartDate: planData.planStartDate,
      currentLicense: planData.licenseId,
      currentProductName: planData.productName,
    });
    this.calculateEndDate(planDays, planData.planStartDate);
  };

  hideEditPlanDrawer = () => {
    this.props.form.resetFields();
    this.getUsedLicenseCount();
    this.setState({
      licenseIdDisabled: true,
      currentEmployee: null,
      currentLicense: null,
      currentLicenseNumber: "",
      currentLicenseName: "",
      currentProduct: null,
      currentProductName: "",
      organizationLicenses: [],
      currentplanStartDate: new Date(),
      planEndDate: "",
      startdate: new Date(),
      planStartDate: "",
      showAssignPlan: true,
      loadMoreOrg: true,
      orgPagerCurrent: 1,
      editPlanVisible: false,
    });
  };

  calculateEndDate = (pID, planStartDate) => {
    let planEndDate = "";
    if (pID == 180) {
      planEndDate = new Date(
        new Date(planStartDate).setDate(new Date(planStartDate).getDate() + 179)
      ).toDateString();
    } else {
      planEndDate = new Date(
        new Date(planStartDate).setDate(new Date(planStartDate).getDate() + 364)
      ).toDateString();
    }

    this.setState({ planEndDate: new Date(planEndDate) });
  };

  onAssign = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values["startdate"] = moment(values["startdate"]).format(dateFormat);
        let sdate = values["startdate"];

        // this.setState({ startdate: sdate });
        this.setState({ disableAssignButton: true, assigningLicense: true });
        this.updateTravelerPlan(sdate);
      } else {
        this.setState({ disableAssignButton: false, assigningLicense: false });
      }
    });
  };

  buyMorePlan = () => {
    this.setState({
      disableAssignButton: false,
      showAssignPlan: true,
      currentplanStartDate: new Date(),
      planEndDate: "",
      startdate: new Date(),
      planStartDate: "",
    });
  };

  updateTravelerPlan = async (sdate) => {
    let data = {
      travellerId: this.state.currentEmployee,
      productId: this.state.currentProduct,
      licenseId: this.state.currentLicense,
      planStartDate: sdate,
    };

    let response = await this.props.guardMeAssignLicense(
      this.props.match.params.orgid
        ? this.props.match.params.orgid
        : this.props.match.params.id,
      data
    );

    if (response.data.message) {
      notifyUser("Plan assigned successfully!", "success");
      this.hideEditPlanDrawer();
      if (this.props.paginginfo && this.props.paginginfo[this.module]) {
        this.handleTableChange(
          this.props.paginginfo[this.module].pagination,
          this.props.paginginfo[this.module].filter,
          {},
          true
        );
        if (this.props.paginginfo[this.module].filters) {
          let filters = this.props.paginginfo[this.module].filters;
          Object.keys(filters).map((k) => {
            filters[k].auto = false;
          });
          this.setState({ filters: filters });
        }
      } else {
        this.handleTableChange(this.state.pagination, {}, {}, true);
      }
      this.setState({ disableAssignButton: false, assigningLicense: false });
    } else {
      this.setState({ disableAssignButton: false, assigningLicense: false });
    }
  };

  GuardMeChangeAssignedPlanDate = async () => {
    let data = {
      travellerId: this.state.currentEmployee,
      licenseId: this.state.currentLicense,
      planStartDate: this.state.startdate.toDateString(),
    };
    let response = await this.props.guardMeChangeAssignedPlanDate(data);

    if (response.data) {
      notifyUser("Plan updated successfully!", "success");
      this.hideEditPlanDrawer();
      if (this.props.paginginfo && this.props.paginginfo[this.module]) {
        this.handleTableChange(
          this.props.paginginfo[this.module].pagination,
          this.props.paginginfo[this.module].filter,
          {},
          true
        );
        if (this.props.paginginfo[this.module].filters) {
          let filters = this.props.paginginfo[this.module].filters;
          Object.keys(filters).map((k) => {
            filters[k].auto = false;
          });
          this.setState({ filters: filters });
        }
      } else {
        this.handleTableChange(this.state.pagination, {}, {}, true);
      }
      this.setState({ disableAssignButton: false, assigningLicense: false });
    } else {
      this.setState({ disableAssignButton: false, assigningLicense: false });
    }

  };

  confirmUnassignEmployee = async () => {
    let data = {
      userId: this.state.currentEmployee,
      licenseId: this.state.currentLicense,
    };
    let response = await OrgAPI.unassignLicense(data);
    if (response.data.message) {
      notifyUser("Plan unassigned successfully!", "success");
      this.hideEditPlanDrawer();
      if (this.props.paginginfo && this.props.paginginfo[this.module]) {
        this.handleTableChange(
          this.props.paginginfo[this.module].pagination,
          this.props.paginginfo[this.module].filter,
          {},
          true
        );
        if (this.props.paginginfo[this.module].filters) {
          let filters = this.props.paginginfo[this.module].filters;
          Object.keys(filters).map((k) => {
            filters[k].auto = false;
          });
          this.setState({ filters: filters });
        }
      } else {
        this.handleTableChange(this.state.pagination, {}, {}, true);
      }
    }
  };

  fetchOrgLicenses = async (value) => {
    let args = {
      orgId: this.state.currentOrganization,
      productId: this.state.currentProduct,
      filters: { licenseNumber: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {},
    };
    let orgLicenses = await OrgAPI.getOrganizationLicensesByProduct(args);
    this.setState({ organizationLicenses: orgLicenses.data });
  };

  loadOrgLicenses = async (e) => {
    if (
      this.state.loadMoreOrg === true &&
      e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight
    ) {
      var _organizationLicenses = this.state.organizationLicenses;
      var _orgPagerCurrent = this.state.orgPagerCurrent + 1;
      let args = {
        orgId: this.state.currentOrganization,
        productId: this.state.currentProduct,
        filters: {
          licenseNumber:
            this.state.currentLicense != null ? this.state.currentLicense : "",
        },
        pagination: { page: _orgPagerCurrent, pageSize: 10 },
        sorter: {},
      };
      let orgLicenses = await OrgAPI.getOrganizationLicensesByProduct(args);
      if (orgLicenses.data.length > 0) {
        orgLicenses.data.map(function(item) {
          _organizationLicenses.push(item);
        });
        this.setState({
          organizationLicenses: _organizationLicenses,
          orgPagerCurrent: _orgPagerCurrent,
        });
      } else {
        this.setState({ loadMoreOrg: false });
      }
    }
  };

  onChangeOrganizationTree = (selected) => {
    if (Permission.isRole("Division Manager")) {
      if (selected === this.props.match.params.orgid) {
        this.props.history.push("../../../../edit/" + selected + "/traveler/");
      } else {
        if (this.props.match.params.orgid > 0) {
          this.props.history.push("../../" + selected + "/traveler/");
        } else {
          this.props.history.push(
            "../../../../" +
              this.props.match.params.id +
              "/divisions/" +
              selected +
              "/traveler/"
          );
        }
      }
    } else {
      if (selected === this.props.match.params.orgid) {
        this.props.history.push("../../../../edit/" + selected + "/traveler/");
      } else {
        if (this.props.match.params.orgid > 0) {
          this.props.history.push("../../" + selected + "/traveler/");
        } else {
          this.props.history.push(
            "./../" + "divisions/" + selected + "/traveler/"
          );
        }
      }
    }
  };

  showAssignRoleWarning = () => {
    this.setState({ assignRoleWarning: true });
  };

  hideAssignRoleWarning = () => {
    this.setState({ assignRoleWarning: false });
  };

  //swicth
  confirmEmployeeStatusToggle = async (selected, userId, plan) => {
    let self = this;
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    if (selected === false) {
      if (plan !== null && plan !== "") {
        self.setState({
          confirmDeactivateEmployeeVisible: true,
          currentPlanToUnassign: plan,
          currentSelectedEmployee: userId,
          currentSelectedEmployeeStatus: selected,
        });
        confirm({
          title: "Confirmation",
          content:
            currentAppLocale.messages["admin.confirm.unassignallplans"] + "?",
          className: "gray-color",
          onOk() {
            self.submitDeactivateEmployee(
              self.state.currentSelectedEmployeeStatus,
              self.state.currentSelectedEmployee
            );
          },
          onCancel() {
            self.cancelDeactivateEmployee();
          },
        });
      } else {
        self.setState({
          confirmDeactivateEmployeeVisible: false,
          currentPlanToUnassign: "",
          currentSelectedEmployee: null,
          currentSelectedEmployeeStatus: null,
        });
        self.updateEmployeeStatus(selected, userId);
      }
    } else {
      this.setState({
        confirmDeactivateEmployeeVisible: false,
        currentPlanToUnassign: "",
        currentSelectedEmployee: null,
        currentSelectedEmployeeStatus: null,
      });
      this.updateEmployeeStatus(selected, userId);
    }
  };

  updateEmployeeStatus = async (selected, userId) => {
    try {
      this.props.updateStatus(userId, selected).then((response) => {
        const { language } = this.props;
        // const currentAppLocale = AppLocale[languaself.cancelDeactivateEmpge.locale];
        if (response.data && response.data.message) {
          let _userStatus = this.state.userStatus || [];
          _userStatus[userId] = selected;

          this.setState({
            userStatus: _userStatus,
            confirmDeactivateEmployeeVisible: false,
            currentPlanToUnassign: "",
            currentSelectedEmployee: null,
            currentSelectedEmployeeStatus: null,
          });
          this.getUsedLicenseCount();
          notifyUser(response.data.message, "success");
          if (this.props.paginginfo && this.props.paginginfo[this.module]) {
            this.handleTableChange(
              this.props.paginginfo[this.module].pagination,
              this.props.paginginfo[this.module].filter,
              {},
              true
            );
            if (this.props.paginginfo[this.module].filters) {
              let filters = this.props.paginginfo[this.module].filters;
              Object.keys(filters).map((k) => {
                filters[k].auto = false;
              });
              this.setState({ filters: filters });
            }
          } else {
            this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
          }
        } else {
          // notifyUser(currentAppLocale.messages["admin.unknown.error"], "error");
          this.setState({
            confirmDeactivateEmployeeVisible: false,
            currentPlanToUnassign: "",
            currentSelectedEmployee: null,
            currentSelectedEmployeeStatus: null,
          });
        }
      });
    } catch (e) {
      console.log("Error:", e);
    }
  };

  submitDeactivateEmployee = (selected, userId) => {
    this.updateEmployeeStatus(selected, userId);
  };
  cancelDeactivateEmployee = () => {
    this.setState({
      confirmDeactivateEmployeeVisible: false,
      currentSelectedEmployee: null,
      currentSelectedEmployeeStatus: null,
      currentPlanToUnassign: "",
    });
  };

  getHeaderKeys = () => {
    var currentPlan;
    return [
      {
        title: <IntlMessages id='admin.userlisting.allmembershipNumber' />,
        dataIndex: 'membershipNumber',
        // width: "20%",
        render: (_text, record) =>
          record.memberPlans &&
          record.memberPlans
            .filter((data) =>
              this.state.isThisGuardMeOrg
                ? this.state.organizationProducts &&
                  this.state.organizationProducts.some((product) => product.name == data.productName)
                : data
            )
            .map(function(data) {
              return (
                <span key={data}>
                  <b> {data.productName + ' - '}</b>
                  {data.licenseNumber}
                  <br />
                </span>
              );
            }),
        filteredValue: this.getSelectedFilterValue('memberProduct'),
        ...this.getColumnSearchProps('membershipNumber')
      },
      {
        title: <IntlMessages id='admin.userlisting.activemembershipNumber' />,
        dataIndex: 'membershipNumber',
        // width: "20%",
        render: (_text, record) => {
          let startdate;
          let enddate;
          currentPlan =
            record.memberPlans &&
            record.memberPlans.find(function(data) {
              startdate = moment(data.planStartDate).format(dateFormat);
              enddate = '';
              if (data.productName.includes('180')) {
                enddate = moment(startdate)
                  .add(179, 'days')
                  .format(dateFormat);
              } else {
                enddate = moment(startdate)
                  .add(364, 'days')
                  .format(dateFormat);
              }

              // if(new Date(data.planStartDate) >= new Date()){return data }
              if (new Date() >= new Date(startdate) && new Date() <= new Date(enddate)) {
                return data;
              }
            });
          return (
            <span key={currentPlan && currentPlan.licenseNumber}>
              {currentPlan && currentPlan.licenseNumber ? (
                <div>
                  <b> {currentPlan.productName} </b>
                  <br />
                  <span> {currentPlan.licenseNumber} </span>
                  <br />
                  <i>{startdate + ' - ' + enddate}</i>
                </div>
              ) : (
                'No Active Plans'
              )}
              <br />
            </span>
          );
        },
        filteredValue: this.getSelectedFilterValue('memberProduct'),
        ...this.getColumnSearchProps('membershipNumber')
      },
      // {
      //   title: <IntlMessages id='admin.userlisting.activemembershipNumber' />,
      //   dataIndex: 'membershipNumber',
      //   // width: "20%",
      //   render: (_text, record) => {
      //     let startdate;
      //     let enddate;
      //     currentPlan =
      //       record.memberPlans &&
      //       record.memberPlans
      //         .filter((data) =>
      //           this.state.isThisGuardMeOrg
      //             ? this.state.organizationProducts &&
      //               this.state.organizationProducts.some((product) => product.name == data.productName)
      //             : data
      //         )
      //         .map(function(data) {
      //           startdate = moment(data.planStartDate).format(dateFormat);
      //           enddate = '';
      //           if (data.productName.includes('180')) {
      //             enddate = moment(startdate)
      //               .add(179, 'days')
      //               .format(dateFormat);
      //           } else {
      //             enddate = moment(startdate)
      //               .add(364, 'days')
      //               .format(dateFormat);
      //           }

      //           // if(new Date(data.planStartDate) >= new Date()){return data }
      //           if (new Date() >= new Date(startdate) && new Date() <= new Date(enddate)) {
      //             return data;
      //           }
      //         });

      //     return (
      //       <span key={currentPlan && currentPlan.licenseNumber}>
      //         {currentPlan && currentPlan.licenseNumber ? (
      //           <div>
      //             <b> {currentPlan.productName} </b>
      //             <br />
      //             <span> {currentPlan.licenseNumber} </span>
      //             <br />
      //             <i>{startdate + ' - ' + enddate}</i>
      //           </div>
      //         ) : (
      //           'No Active Plans'
      //         )}
      //         <br />
      //       </span>
      //     );
      //   },
      //   filteredValue: this.getSelectedFilterValue('memberProduct'),
      //   ...this.getColumnSearchProps('membershipNumber')
      // },
      // {
      //   title: <IntlMessages id="admin.org.licenselist.product" />,
      //   dataIndex: "plantype",
      //   hidden : !this.state.isThisGuardMeOrg,
      //   // width: "20%",
      //   render: (_text, record) => {
      //     console.log('check record ', record)
      //     if (record.memberProduct){
      //       return record.memberProduct && (
      //         <div>{record.memberProduct.productName}</div>
      //        )
      //     }
      //   }
      // },
      // {
      //   title: <IntlMessages id="admin.geofencing.startDate" />,
      //   dataIndex: "startdate",
      //   hidden : !this.state.isThisGuardMeOrg,
      //   // width: "20%",
      //   render: (_text, record) => {

      //   if (record.memberPlan){
      //     return record.memberPlan.planStartDate && (
      //       <div>
      //         {moment(record.memberPlan.planStartDate).format(dateFormat)}
      //     </div>
      //     )
      //   }
      //  }
      // },
      // {
      //   title: <IntlMessages id="admin.geofencing.endDate" />,
      //   dataIndex: "enddate",
      //   hidden : !this.state.isThisGuardMeOrg,
      //   // width: "20%",
      //   render: (_text, record) => {
      //     if (record.memberProduct){

      //       let productId = record.memberProduct.productId;
      //       let startdate = moment(record.memberPlan.planStartDate).format(dateFormat);
      //       let enddate = '';
      //         if((record.memberProduct.productName).includes("180")) {
      //           // this.setState({isSixMonthPlan : true});
      //           enddate = moment(startdate).add(180,"days").format(dateFormat);
      //         }else{
      //           // this.setState({isSixMonthPlan : false})
      //           enddate = moment(startdate).add(364,"days").format(dateFormat);
      //         }
      //       return (
      //         <div>{enddate}</div>
      //       )
      //     }

      //   }
      // },
      {
        title: <IntlMessages id='admin.userlisting.firstName' />,
        dataIndex: 'firstName',
        filteredValue: this.getSelectedFilterValue('firstName'),
        ...this.getColumnSearchProps('firstName')
        //width: "15%"
      },
      {
        title: <IntlMessages id='admin.userlisting.lastName' />,
        dataIndex: 'lastName',
        filteredValue: this.getSelectedFilterValue('lastName'),
        ...this.getColumnSearchProps('lastName')
        // width: "15%"
      },
      {
        title: <IntlMessages id='admin.userlisting.email' />,
        dataIndex: 'userName',
        filteredValue: this.getSelectedFilterValue('userName'),
        ...this.getColumnSearchProps('userName')
        //width: "15%"
      },
      {
        title: <IntlMessages id='admin.userlisting.phone' />,
        dataIndex: 'contactNo',
        //width: "15%",
        filteredValue: this.getSelectedFilterValue('contactNo'),
        ...this.getColumnSearchProps('contactNo')
      },
      {
        title: <IntlMessages id='admin.userlisting.status' />,
        render: (_text, record) => (
          <span>
            <Switch
              checkedChildren={<IntlMessages id='admin.status.active' />}
              unCheckedChildren={<IntlMessages id='admin.status.inactive' />}
              checked={record.isActive}
              onClick={() =>
                this.confirmEmployeeStatusToggle(
                  this.state.userStatus[record.userId] ? !this.state.userStatus[record.userId] : !record.isActive,
                  record.userId,
                  record.memberPlan && record.memberPlan.licenseNumber ? record.memberPlan.licenseNumber : null
                )
              }
            />
          </span>
        )
      },

      {
        title: <IntlMessages id='admin.manageOrders.Action' />,
        rowKey: 'action',
        //width: "10%",
        render: (text, record) => (
          <span>
            <EditCell onEditCell={() => this.props.history.push('edit/' + record.userId)} />

            {/* <button
              className="icon"
              onClick={() => {
                this.resendMembershipInfo(record.userId);
              }}
              >
              <Tooltip
                title={
                  <IntlMessages id="admin.traveler.membershipinformation" />
                }
              >
                <i className="fas fa-info-circle"></i>
              </Tooltip>
            </button> */}
            {record.role == null || record.role == '' ? (
              <button className='icon' onClick={() => this.showAssignRoleWarning()}>
                <Tooltip title={<IntlMessages id='admin.employeesList.manage.plan' />}>
                  <i className='fas fa-id-card'></i>
                </Tooltip>
              </button>
            ) : this.state.isThisGuardMeOrg ? (
              <button
                style={{ opacity: record.isActive === true ? '1' : '0.5' }}
                disabled={!record.isActive}
                className='icon'
                onClick={() => this.showEditPlanDrawer(record)}
              >
                <Tooltip title={<IntlMessages id='admin.employeesList.manage.plan' />}>
                  <i className='fas fa-id-card'></i>
                </Tooltip>
              </button>
            ) : (
              ''
            )}
          </span>
        )
      }
    ].filter((item) => !item.hidden);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys == "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto: true,
    };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    if (filters[dataIndex] && !filters[dataIndex].auto) {
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange(
        { current: 1, pageSize: 10 },
        this.props.paginginfo[this.module].filter,
        {}
      );
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    this.props.clearPaginationExceptMe(this.module);
    if (this.props.paginginfo && this.props.paginginfo[this.module]) {
      this.handleTableChange(
        this.props.paginginfo[this.module].pagination,
        this.props.paginginfo[this.module].filter,
        {},
        true
      );
      if (this.props.paginginfo[this.module].filters) {
        let filters = this.props.paginginfo[this.module].filters;
        Object.keys(filters).map((k) => {
          filters[k].auto = false;
        });
        this.setState({ filters: filters });
      }
    } else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
    }

    this.getUsedLicenseCount();
    let orgProducts = await this.props.getGuardMeProducts(
      this.props.match.params.orgid
    );
    let isGuardMeOrg = await OrgAPI.getOrganization(
      this.props.match.params.orgid
        ? this.props.match.params.orgid
        : this.props.match.params.id
    ).then((response) => {
      return response.data.isGuardMeOrg;
    });

    this.setState({
      isThisGuardMeOrg: isGuardMeOrg,
      organizationProducts: orgProducts,
      currentOrganization: this.props.match.params.id,
      // employeesLoaded: true
    });
    let assignedOrgProudcts = [];
    this.state.licenseTotals &&
      this.state.licenseTotals.map((el) => {
        orgProducts.map((item) => {
          if (el.product == item.name) {
            assignedOrgProudcts.push(item);
          }
        });
      });

    this.setState({ organizationProductsInfo: assignedOrgProudcts });
  }

  getUsedLicenseCount = () => {
    //  if (
    //   Permission.isTopLevelManager() &&
    //   Permission.canAccess("license_count")
    // ) {
    this.props
      .getLicenseCount(this.props.match.params.id)
      .then((response) => {
        let _allTotalLicenses = 0,
           _activeLicenses = 0,
            _allexpiredLicenses = 0,
          _allAssignedLicenses = 0;
          
        if (response.data && response.data.length) {
          response.data.map(function(el) {
            _allexpiredLicenses += el.expiredLicenses;
              _activeLicenses += el.activeLicenses;
            _allTotalLicenses += el.totalLicenses;
            _allAssignedLicenses += el.usedLicenses;
          });
        }
        this.setState({
          allActiveLicenses: _activeLicenses,
          allTotalLicenses: _allTotalLicenses,
          allExpiredLicenses: _allexpiredLicenses,
          allAssignedLicenses: _allAssignedLicenses,
          licenseTotals: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    //  }
  };

  deleteItem = (id) => {
    this.setState({ loading: true });
    this.props
      .deleteUser({ id: id })
      .then((_item) => {
        this.setState({ loading: false });
        this.props.history.push("./");
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter, manual) => {
    this.setState({ data: [] });
    if (filters === undefined) filters = {};
    Object.keys(filters).map((key) => {
      if (
        !filters[key] ||
        (Array.isArray(filters[key]) && filters[key].length === 0)
      ) {
        delete filters[key];
      }
    });
    filters["orgId"] = this.props.match.params.id;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if (manual !== true) {
      this.props.updatePaginationRemoveOld({
        module: this.module,
        filter: filters,
        pagination: {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
      });
    }
    this.setState({ loading: true });
    this.props
      .getTravellersListing({
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then((resp) => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  onOrgProductChange = async (productId) => {
    // let productData =
    //   this.state.organizationProductsInfo &&
    //   this.state.organizationProductsInfo.find((el) => {
    //     return el.productId == productId;
    //   });

    // let planDays = productData && productData.name.substr(-3);
    
    
    let productData =
      this.state.licenseTotals &&
      this.state.licenseTotals.find((el) => {
        return el.productId == productId;
      });

    let planDays = productData && productData.product.substr(-3);
    
    this.setState({ planEndDate: "" });

    let startdate = new Date(this.state.startdate);
    let enddate = "";
    if (planDays == 180) {
      this.setState({ isSixMonthPlan: true });
      enddate = new Date(
        startdate.setDate(startdate.getDate() + 179)
      ).toDateString();
    } else {
      this.setState({ isSixMonthPlan: false });
      enddate = new Date(
        startdate.setDate(startdate.getDate() + 364)
      ).toDateString();
    }

    this.setState({ enddate: new Date(enddate) });
    this.setState(
      {
        currentProduct: productId,
        currentLicense: null,
        editPlanVisible: true,
        licenseIdDisabled: false,
      },
      () => {
        this.fetchOrgLicenses("");
        this.props.form.setFieldsValue({ licenseId: null });
        this.props.form.setFieldsValue({ enddate: moment(new Date(enddate)) });
      }
    );
  };

  onChangeStartDate = (e) => {
    this.setState({ planEndDate: "" });
    let startDate = new Date(e.format(dateFormat));
    let endDate = "";
    this.setState({ startdate: new Date(startDate) });

    if (this.state.isSixMonthPlan) {
      endDate = new Date(
        startDate.setDate(startDate.getDate() + 179)
      ).toDateString();
    } else {
      endDate = new Date(
        startDate.setDate(startDate.getDate() + 364)
      ).toDateString();
    }

    this.setState({ enddate: new Date(endDate) });
    this.props.form.setFieldsValue({ enddate: moment(new Date(endDate)) });
    
  };

  DownloadTravlerList = (orgId) => {
    let token = UserServices.getToken();
    let _headers = { "Content-Type": "multipart/form-data" };
    if (token)
      _headers["authorization"] = token ? "Bearer " + token : undefined;
    let filename = "Traveller_list.csv";
    let apiUrl = config.API1 + "/api/ui/ExportGuardMeTravellersList/" + orgId;
    axios({
      url: apiUrl,
      method: "GET",
      responseType: "blob",
      headers: _headers,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    let parentOrg = this.props.match.params.orgid
      ? this.props.match.params.orgid
      : this.props.match.params.id;
    let defaultParentOrg = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.match.params.orgid
      ? this.props.match.params.orgid
      : this.props.match.params.divid;
    if (Permission.isRole("Division Manager")) {
      let currentUser = localStorage.getItem("user");
      if (currentUser !== "") {
        currentUser = JSON.parse(currentUser);
        parentOrg = currentUser.companyID;
      }
      defaultParentOrg = this.props.match.params.id
        ? this.props.match.params.id
        : parentOrg;
    }

    return (
      <div>
        <Modal
          title={<IntlMessages id='admin.warning.rolemissing' />}
          closable={false}
          visible={this.state.assignRoleWarning}
          onOk={this.hideAssignRoleWarning}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <p>
            <IntlMessages id='admin.warning.assignrole' />
          </p>
        </Modal>
        <Modal
          title={<IntlMessages id='admin.employees.bulkupload.modal.heading' />}
          closable={false}
          visible={this.state.isBulkUploadModalVisible}
          footer={[
            <Button onClick={this.cancelBulkUploadEmployees}>
              <IntlMessages id='admin.button.cancel' />
            </Button>,
            <Button onClick={this.initBulkUploadEmployees} type='primary'>
              <IntlMessages id='admin.button.submit' />
            </Button>
          ]}
        >
          <Row gutter={24} style={{ marginBottom: '20px' }}>
            <Col xs={24} sm={24} md={24}>
              <label>
                <strong>
                  <IntlMessages id='admin.employees.bulkupload.modal.selectFile' />:
                </strong>
              </label>
              <br />
              <Upload {...this.csvUploadProps()}>
                <Button type='default'>
                  <Icon type='upload' /> <IntlMessages id='admin.upload.chooseFile' />
                </Button>
              </Upload>
              <span>
                {this.state.bulkUploadInstance.uploadFileName !== '' ? (
                  <div>
                    <br />
                    {this.state.bulkUploadInstance.uploadFileName}
                    <button title='Remove' className='icon' onClick={this.removeBulkUploadFile}>
                      <i className='fas fa-trash'></i>
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </span>
            </Col>
          </Row>
          {
            <Row gutter={24} style={{ marginBottom: '20px' }}>
              <Col xs={24} sm={24} md={24}>
                <label>
                  <strong>
                    <IntlMessages id='admin.userlisting.sendWelcomeEmail' />
                  </strong>
                </label>
                <br />
                <Radio.Group
                  onChange={this.onChangeSendEmailToggle}
                  value={this.state.bulkUploadInstance.sendEmailForNew}
                >
                  <Radio value={false}>No</Radio>
                  <Radio value={true}>Yes</Radio>
                </Radio.Group>
              </Col>
            </Row>
          }
          <Row gutter={24} style={{ marginBottom: '20px' }}>
            <Col xs={24} sm={24} md={24}>
              <label>
                <strong>
                  <IntlMessages id='admin.employees.bulkupload.modal.assignMembership' />
                </strong>
              </label>
              <br />
              <Radio.Group
                onChange={this.onChangeAssignMembershipToggle}
                value={this.state.bulkUploadInstance.isAssignMembership}
              >
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginBottom: '20px',
              display: this.state.bulkUploadInstance.isAssignMembership ? 'block' : 'none'
            }}
          >
            <Col xs={24} sm={24} md={24}>
              <label>
                <strong>
                  <IntlMessages id='admin.employees.bulkupload.modal.assignMembershipProduct' />:
                </strong>
              </label>
              <br />
              <Select
                placeholder='-select-'
                onChange={this.onMembershipProductChange}
                style={{ minWidth: '240px' }}
                value={this.state.bulkUploadInstance.product}
                defaultActiveFirstOption={false}
              >
                <Option key={-1} value={-1}>
                  -Select-
                </Option>
                {this.state.licenseTotals
                  ? this.state.licenseTotals.map((el) => {
                      return (
                        <Option key={el.productId} value={el.productId}>
                          {el.product}
                        </Option>
                      );
                    })
                  : ''}
              </Select>
            </Col>
          </Row>
        </Modal>
        {
          <Drawer
            title={<IntlMessages id='admin.employeesList.manage.plan' />}
            width={420}
            onClose={this.hideEditPlanDrawer}
            visible={this.state.editPlanVisible}
            bodyStyle={{ paddingBottom: 80 }}
            closable={true}
            maskClosable={false}
          >
            <Spin spinning={this.state.assigningLicense}>
              <Form layout='vertical'>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item label={<IntlMessages id='admin.assign.plan.name' />}>
                      {this.state.showAssignPlan ? (
                        getFieldDecorator('productId', {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ],
                          defaultValue: this.state.currentProduct > 0 ? this.state.currentProduct : null
                        })(
                          <Select placeholder='-select-' onChange={this.onOrgProductChange}>
                            {this.state.licenseTotals
                              ? this.state.licenseTotals.map((el) => {
                                  //  this.state.licenseTotals.map((item)=> {
                                  // if(item.product == el.name){
                                  return (
                                    <Option key={el.productId} value={el.productId}>
                                      {el.product}
                                    </Option>
                                  );
                                  //   }
                                  //  })
                                })
                              : ''}
                          </Select>
                        )
                      ) : (
                        <Input disabled value={this.state.currentProductName} />
                      )}
                    </Form.Item>
                  </Col>
                  {}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item label={<IntlMessages id='admin.assign.plan.number' />}>
                      {this.state.showAssignPlan ? (
                        getFieldDecorator('licenseId', {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ]
                        })(
                          <Select
                            showSearch
                            allowClear
                            onSelect={this.onOrgLicenseChange}
                            onDeselect={this.onOrgLicenseDeselect}
                            onSearch={this.fetchOrgLicenses}
                            placeholder='Type to search'
                            disabled={this.state.licenseIdDisabled}
                            onPopupScroll={this.loadOrgLicenses}
                            defaultActiveFirstOption={false}
                          >
                            {this.state.organizationLicenses
                              ? this.state.organizationLicenses.map((el) => {
                                  return <Option key={el.licenseId}>{el.licenseNumber}</Option>;
                                })
                              : null}
                          </Select>
                        )
                      ) : (
                        // <Input  value={this.state.currentLicenseName} />
                        <Select
                          onSelect={this.handleSelectedPlanData}
                          showSearch
                          placeholder={
                            this.state.currentLicenseNumber &&
                            this.state.currentLicenseNumber != null &&
                            this.state.currentLicenseNumber
                          }
                        >
                          {this.state.memberPlansList &&
                            this.state.memberPlansList
                              .filter(
                                (data) =>
                                  this.state.organizationProducts &&
                                  this.state.organizationProducts.some((product) => product.name == data.productName)
                              )
                              .map((item, index) => {
                                return (
                                  <Option key={item.licenseId} value={item.licenseId}>
                                    {item.licenseNumber}
                                  </Option>
                                );
                              })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item label={<IntlMessages id='Start Date' />}>
                      {getFieldDecorator('startdate', {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id='admin.input.required' />
                          }
                        ],

                        initialValue: this.state.currentplanStartDate
                          ? moment(this.state.currentplanStartDate, dateFormatY)
                          : moment(this.state.startdate, dateFormatY)
                      })(
                        <DatePicker
                          onChange={(e) => this.onChangeStartDate(moment(e, dateFormatY))}
                          format={dateFormat}
                          disabledDate={(current) => current && current < moment().subtract(1, 'days')}
                          disabled={
                            !this.state.showAssignPlan &&
                            (moment(this.state.currentplanStartDate, 'YYYY-MM-DDTHH:mm:ss').add(30, 'days').isBefore(moment().endOf('day'))
                            )
                          }
                         // disabled={moment(this.state.currentplanStartDate) < moment() && !this.state.showAssignPlan}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item label={<IntlMessages id='End Date' />}>
                      {getFieldDecorator('enddate', {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id='admin.input.required' />
                          }
                        ],
                        initialValue: this.state.planEndDate
                          ? moment(this.state.planEndDate, dateFormatY)
                          : moment(this.state.enddate, dateFormatY)
                      })(<DatePicker format={dateFormat} disabled={true} />)}
                    </Form.Item>
                  </Col>
                </Row>
                {this.state.showAssignPlan ? (
                  <Button onClick={this.onAssign} disabled={this.state.disableAssignButton} type='primary'>
                    Assign
                  </Button>
                ) : (
                  <>
                    <Popconfirm
                      placement='bottomLeft'
                      title='Are you sure, you want to unassign the plan number?'
                      onConfirm={this.confirmUnassignEmployee}
                      okText='Yes'
                      cancelText='No'
                      disabled={moment(this.state.currentplanStartDate) < moment()}
                    >
                      <Button disabled={moment(this.state.currentplanStartDate) < moment()} type='primary'>
                        Unassign
                      </Button>
                    </Popconfirm>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={this.GuardMeChangeAssignedPlanDate}
                      disabled={moment(this.state.currentplanStartDate, 'YYYY-MM-DDTHH:mm:ss').add(30, 'days').isBefore(moment().endOf('day'))}
                      ///disabled={moment(this.state.currentplanStartDate) < moment() &&  this.state.DisableAfterThirtyday}
                      type='primary'
                    >
                      Update
                    </Button>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={this.buyMorePlan}
                      type='primary'
                      disabled={!this.state.disableAssignButton}
                    >
                      Assign Additional Plan
                    </Button>
                  </>
                )}
              </Form>
            </Spin>
          </Drawer>
        }
        <Spin tip={<IntlMessages id='admin.loading.pleaseWait' />} spinning={this.state.uploadingEmployees}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Typography.Title level={4}>
                <IntlMessages id='admin.traveler.managetraveler' />
                {this.props.history.location.state &&
                this.props.history.location.state.orgName &&
                this.props.history.location.state.orgName !== null
                  ? ' (' + this.props.history.location.state.orgName + ')'
                  : ''}
              </Typography.Title>
            </Col>
            {
              <Col xs={12} sm={12} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                {this.state.licenseTotals && this.state.licenseTotals.length > 0 ? (
                  <div>
                    <IntlMessages id='admin.organization.licences.used' />
                    :&nbsp;
                    <Popover
                      placement='bottomRight'
                      content={
                        this.state.licenseTotals
                          ? this.state.licenseTotals.map(function(el, idx) {
                              return (
                                <div key={idx} style={{ fontSize: '16px' }}>
                                  {el.product}:&nbsp;
                                  <strong>
                                    {el.usedLicenses} / {el.totalLicenses}
                                  </strong>
                                </div>
                              );
                            })
                          : ''
                      }
                      trigger='hover'
                    >
                      <strong>
                        {this.state.allAssignedLicenses} / {this.state.allTotalLicenses}
                      </strong>
                      <span style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                        Active Licenses : <strong>{this.state.allActiveLicenses}</strong>
                      </span>
                      <span>
                        Expired Licenses: <strong>{this.state.allExpiredLicenses}</strong>
                      </span>
                    </Popover>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            }
          </Row>
          <hr />
          <Row gutter={24}>
            <Col sx={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'right' }}>
              <ul className='upload-xlx'>
                {this.state.isThisGuardMeOrg ? (
                  <>
                    <li>
                      {' '}
                      <Button onClick={() => this.DownloadTravlerList(this.props.match.params.id)}>
                        <Icon type='download' /> <IntlMessages id='admin.b2b2c.download.travlerlist' />
                      </Button>
                    </li>
                    <li>
                      {' '}
                      <Button onClick={() => this.downloadSampleCsv()}>
                        <Icon type='download' /> <IntlMessages id='admin.organization.download.csv' />
                      </Button>
                    </li>
                    <li>
                      <Button onClick={() => this.setState({ isBulkUploadModalVisible: true })}>
                        <Icon type='upload' /> <IntlMessages id='admin.b2b2c.upload.csv' />
                      </Button>
                    </li>
                    <li>
                      <Button type='primary' onClick={() => this.editItem('new')}>
                        <IntlMessages id='admin.b2b2c.add.travelor' />
                      </Button>
                    </li>
                  </>
                ) : (
                  ''
                )}
                <li>
                  <Button type='primary' className='' htmlType='button' onClick={() => this.props.history.goBack()}>
                    <IntlMessages id='admin.userlisting.back' />
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: '20px' }}>
            <Col xs={24}>{filtertag}</Col>
          </Row>
          {!this.state.loading && this.state.isThisGuardMeOrg ? (
            <Row gutter={24} style={{ paddingBottom: '20px' }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ float: 'left', paddingTop: '5px' }}>
                  <IntlMessages id='admin.organization.company' />
                  :&nbsp;
                </div>
                <div style={{ float: 'left', minWidth: '320px' }}>
                  <OrgTree
                    parent={defaultParentOrg}
                    organizationId={parentOrg}
                    onChange={this.onChangeOrganizationTree}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ overflowX: 'auto' }}>
              <Table
                bordered
                columns={this.getHeaderKeys()}
                rowKey={(record) => record.userId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

ManageTravelers.propTypes = {
  location: PropTypes.object,
  getTravellersListing: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    ...state.traveller,
    ...state.user,
    ...state.pagination,
    ...state.language,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...travellerActions,
      ...userActions,
      ...B2B2COrgActions,
      ...paginationActions,
      ...b2b2cActions,
      ...OrgPlanTypeActions,
    },
    dispatch
  );
}

const WrappedForm = Form.create({ name: "edit-traveler-plan" })(
  ManageTravelers
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
