let FPConfing = window.FPConfing || {};
module.exports = {
  API1: FPConfing && FPConfing.API1 ? FPConfing.API1 : "https://api.captravelassistance.com",
  API2: FPConfing && FPConfing.API2 ? FPConfing.API2 : "https://login.captravelassistance.com",
  API3: FPConfing && FPConfing.API3 ? FPConfing.API3 : "https://api.captravelassistance.com",
  DASHBOARD_URL:
    FPConfing && FPConfing.DASHBOARD_URL ? FPConfing.DASHBOARD_URL : "https://api.captravelassistance.com:3000",
  allCountriesCountryId: FPConfing && FPConfing.allCountriesCountryId ? FPConfing.allCountriesCountryId : 277,
  allCountriesCountryCode: FPConfing && FPConfing.allCountriesCountryCode ? FPConfing.allCountriesCountryCode : "All",
  stripeKey: FPConfing && FPConfing.stripeKey2 ? FPConfing.stripeKey2 : "pk_live_NN8idQPTzXxMi1SYmyQjhdmK",
 // stripeKey: FPConfing && FPConfing.stripeKey2 ? FPConfing.stripeKey2 : "pk_test_MKEr3yMzeJIaUhGQvWZfTu5n",
  // : "pk_test_xE1A4jL9sDBnyBWljkVfKD0S00CPA7kVzU",
  b2cBaseUrl: "/cap",
  b2b2cBaseUrl: "/partner",
  wpEditQuoteUrl: "https://captravelassistance.com/",
  agentCode: 265, //265 for prod, 1640 -dev
  wpProductToOrder: 1,
  b2b2CProductToOrder: 7, //7 for prod 4-dev
  server: "prod", // "prod" for production  "dev" -dev
  ttl: 300000,
  clientTokenPaypal: "AdUorQYFW95ItBjosIlhKfU0bKtwPmWUIBCWL3Oose0Pvd357xwyttF_g4DDPzU6TJQPP-meMnZoSN20",

 MD2InternationOrgID: 645,

planUpgradePrice: 351.4,
  
 familypurchaseprice: '33.60',
 
 familypurchaseId: '688',

//  sixMonthPlanNoId : "11",
 
  // twelveMonthPlanNoId: "12",
  
//  capMedSixId : "13",
  
 // capMedTweleveId : "14",
 
//   twelveMonthWithoutAssistId :"15"

  //dev  -   Abmle8eLyxI7-pWsG5dlUNkw7CmuIZsmz1qKcTm6RoPLo0JHAyyACv_c3nuSf7hVGkp6Al22Ky7hugRM

  //prod -   AdUorQYFW95ItBjosIlhKfU0bKtwPmWUIBCWL3Oose0Pvd357xwyttF_g4DDPzU6TJQPP-meMnZoSN20

};