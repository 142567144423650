import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import moment from "moment";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import * as userActions from "../../../redux/actions/user-actions";
import { WrappedRegistrationForm } from "./traveller-form";
import { Row, Col, Button, Modal } from "antd";
import { Beforeunload } from "react-beforeunload";
import { currencyType } from "../../constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { cadConvertionRate } from "../../b2c/shared/cadConverstion";
import PriceCalculation from "./price-calculation.jsx";
import config from "../../../../../config.js";
const { confirm } = Modal;

class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: props.planTerm ? props.planTerm.totalPrice : 0,
      planMaxPrice: 0,
      productToOrder: config.b2b2CProductToOrder,
      appliedPromoText: "",
      selectedcountryTierList: [],
      getParams: {
        start_date: "",
        end_date: "",
        traveller_count: 1,
        countries: [],
        travel_type: "",
      },
      currency: "USD",
      currencyRate: 1,
      agentName: "",
      current_travellers: 1,
      genderList: [],
    };
    this.handleBack = this.handleBack.bind(this);
    //this.updatePrice = this.updatePrice.bind(this);
  }

  getUrlDecodedParams() {
    let localDecodedParams = localStorage.getItem("decodedParams");
    if (
      typeof localDecodedParams !== "undefined" &&
      localDecodedParams !== null &&
      localDecodedParams !== ""
    ) {
      return JSON.parse(localDecodedParams);
    }
    var encodedParameters = this.props.location.search;
    var getEncodedStr = encodedParameters.substring(1).split("&");
    let patttern = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    getEncodedStr.map((str) => {
      encodedParameters = str.match(patttern) ? str : "";
    });

    var decodedParameterString = atob(encodedParameters);
    var parameters = decodedParameterString.split("&");
    var parameterArray = new Map();

    parameters.forEach(async function(parameter) {
      var data = parameter.split("=");
      parameterArray[data[0]] = data[1];
    });
    var selectedGetCountries = parameterArray["countries"].split(",");
    var selectedGetCountriesStr = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      if (
        selectedGetCountries.indexOf(
          this.props.countryTierInfo[i].countryCode
        ) > -1
      ) {
        selectedGetCountriesStr.push(this.props.countryTierInfo[i].countryName);
      }
    }

    // this.setState({
    //   currency: "CAD",

    // });

    return {
      start_date: parameterArray["start_date"],
      end_date: parameterArray["end_date"],
      traveller_count: parseInt(parameterArray["traveller_count"]),
      countries: parameterArray["countries"],
      countries_list: selectedGetCountriesStr.join(", "),

      travel_type: parameterArray["travel_type"],
      travel_type_label: parameterArray["traveltypelabel"],
      currency_code: parameterArray["currency_code"],
    };
  }

  async componentDidMount() {
    let is_back = localStorage.getItem("is_back");
    window.scrollTo(0, 0);
    await this.props.getPersonalInformation();
    // if (this.props.planTerm && Object.keys(this.props.planTerm).length === 0) {
    await this.props.getStep();
    await this.props.getUserData();
    await this.props.getPlanInformation();
    await this.props.getPlanTermInformation();
    await this.props.getResellerProductPricingAndInfo();
    // console.log("mounted");

    //to get atn no from url
    let params = new URLSearchParams(window.location.search);
    let atn = params.get("atn");
    await this.props.getAgentName(atn);

    var encodedParameters = this.props.location.search;
    var getEncodedStr = encodedParameters.substring(1).split("&");
    let patttern = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    getEncodedStr.map((str) => {
      encodedParameters = str.match(patttern) ? str : "";
    });

    var decodedParameterString = atob(encodedParameters);
    var parameters = decodedParameterString.split("&");
    var parameterArray = new Map();
    parameters.forEach(async function(parameter) {
      var data = parameter.split("=");
      parameterArray[data[0]] = data[1];
    });

    this.setState({
      currency: parameterArray["currency_code"],
      //currencyRate: parameterArray["cur_rate"],
    });
    
    if (is_back != "yes") {
      this.setState({
        currency: parameterArray["currency_code"],
      });
      this.handleCurrencyChange(parameterArray["currency_code"]);
    }

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    var start_date = Date.parse(parameterArray["start_date"]);
    var end_date = Date.parse(parameterArray["end_date"]);
    var days = Math.floor((end_date - start_date) / _MS_PER_DAY) + 1;

    let dp = 8;
    if (days <= 8) {
      dp = 8;
    } else if (days <= 15) {
      dp = 15;
    } else if (days <= 30) {
      dp = 30;
    } else if (days <= 60) {
      dp = 60;
    } else if (days <= 90) {
      dp = 90;
    } else if (days <= 120) {
      dp = 120;
    } else if (days <= 365) {
      dp = 365;
    }

    var selectedGetCountries = parameterArray["countries"].split(",");
    var selectedGetCountriesStr = [];
    var selectedGetCountriesArr = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      if (
        selectedGetCountries.indexOf(
          this.props.countryTierInfo[i].countryCode
        ) > -1
      ) {
        selectedGetCountriesArr.push(this.props.countryTierInfo[i].countryId);
        selectedGetCountriesStr.push(this.props.countryTierInfo[i].countryName);
      }
    }

    var decodedParamsObj = {
      start_date: parameterArray["start_date"],
      end_date: parameterArray["end_date"],
      traveller_count: parseInt(parameterArray["traveller_count"]),
      countries: parameterArray["countries"],
      countries_list: selectedGetCountriesStr.join(", "),
      travel_type: parameterArray["travel_type"],
      travel_type_label: parameterArray["traveltypelabel"],
    };
    this.setState(
      {
        dayPackage: dp,
        getParams: decodedParamsObj,
      },
      () => {
        let hasQs = localStorage.getItem("qs");
        if (
          typeof hasQs !== "undefined" &&
          hasQs !== null &&
          hasQs !== "" &&
          hasQs === window.location.search.substring(1)
        ) {
        } else {
          localStorage.setItem(
            "decodedParams",
            JSON.stringify(decodedParamsObj)
          );
          localStorage.setItem("qs", window.location.search.substring(1));
        }
      }
    );
    //this.calculateProductPrice(selectedGetCountriesArr, dp);

    /* save plan type */
    let planType = this.state.productToOrder;
    let planObject = this.props.productInfo.find(
      (x) => x.productId === parseInt(planType)
    );
    await this.props.SaveStep("1");
    await this.props.SavePlanType(planObject);

    /* save plan term */
    let planTermObject = {};
    planTermObject.date = parameterArray["start_date"];
    planTermObject.endDate = parameterArray["end_date"];
    planTermObject.days = Math.floor((end_date - start_date) / _MS_PER_DAY);
    planTermObject.dp = dp;
    planTermObject.country = selectedGetCountriesArr.join(",");
    planTermObject.ustravel_states = parameterArray["res_state_lists"];
    planTermObject.totalPrice = this.state.productPrice;
    planTermObject.Price =
      this.state.productPrice / parseInt(parameterArray["traveller_count"]);
    planTermObject.tierValue = this.state.selectedCountryTier;
    planTermObject.isAnnual = dp == 365 ? true : false; //this.state.is_annual;
    await this.props.SaveStep("2");
    await this.props.SavePlanTerm(planTermObject);

    this.props.getGendersList().then((response) => {
      this.setState({ genderList: response });
    });
  }

  async handleBack() {
    await this.props.SaveStep("2");
    //this.props.history.push(this.props.b2b2cBaseUrl + "/plan-term");
    // this.props.history.push(this.props.b2b2cBaseUrl+ "/plan-term/"+this.props.match.params.orgId + window.location.search);
  }

  onUnload = (e) => {
    // localStorage.removeItem("qs");
    // localStorage.removeItem("decodedParams");
    e.preventDefault();
    //this.props.flushOrderData();
    //this.props.ClearData();
  };

  updateTravellerCount = (count) => {
    this.props.travellerCount(count);
    let _this = this;
    let _getParams = this.state.getParams;
    _getParams.traveller_count = Math.max(1, count);
    this.setState({ getParams: _getParams }, () => {
      localStorage.setItem("decodedParams", JSON.stringify(_getParams));
      //  _this.onChangeAnnualCB(_this.props.planTerm.isAnnual);
    });
  };

  async upgradePlan(count) {
    this.props.travellerCount(count);
    let _this = this;
    let _getParams = this.state.getParams;
    _getParams.traveller_count = Math.max(1, count);
    this.setState({ getParams: _getParams }, () => {
      localStorage.setItem("decodedParams", JSON.stringify(_getParams));
      //_this.onChangeAnnualCB(_this.props.planTerm.isAnnual);
    });
  }

  getDayPackgeFromParams = () => {
    let decodedParams = this.getUrlDecodedParams();
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    var start_date = Date.parse(decodedParams.start_date);
    var end_date = Date.parse(decodedParams.end_date);
    var days = Math.floor((end_date - start_date) / _MS_PER_DAY) + 1;
    let dp = 8;
    if (days <= 8) {
      dp = 8;
    } else if (days <= 15) {
      dp = 15;
    } else if (days <= 30) {
      dp = 30;
    } else if (days <= 60) {
      dp = 60;
    } else if (days <= 90) {
      dp = 90;
    } else if (days <= 120) {
      dp = 120;
    } else if (days <= 365) {
      dp = 365;
    }
    return dp;
  };
  
  handleCurrencyChange = async (value) => {
    // var value = e.target.value;
    this.setState({ currency: value });

    var currencyData = "";
    await this.props.convertCurrency().then((resp) => {
      currencyData = resp;
    });
    if (this.state.currency == "USD") {
      this.setState({ currencyRate: 1 });
      this.props.updateCurrency(value, 1);
    } else {
      this.setState({ currencyRate: currencyData.value });
      this.props.updateCurrency(value, currencyData.value);
    }
    //  this.updatePrice();
  };

  render() {
    var decodedParams = this.getUrlDecodedParams();
    var plus1Year = decodedParams.start_date;

    plus1Year = moment(plus1Year)
      .add("364", "days")
      .format("YYYY-MM-DD");
    const backPageurl =
      decodedParams.us_travel_states &&
      decodedParams.us_travel_states != undefined
        ? "domestic-quote-results/"
        : "quote-results/";

    return (
      <section id="banner" className="b2b2c-personal-details">
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <Wizard wizardActiveClass="step3" />

          {this.props.countryTierInfo && this.props.countryTierInfo.length > 0 && this.props.planType ? (
            <div>
              <div
                className="wrap900 price-sticky-head"
                style={{
                  display: "block",
                  maxWidth: "1300px",
                  zIndex: "100",
                  position: "sticky",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding: "16px"
                }}
              >
                {this.props.agentName && this.props.match.params.orgId == config.agentCode
                  ? "Agent : " + this.props.agentName
                  : ""}
                <br />
              </div>

              <div
                className="wrap900 price-sticky-head"
                style={{
                  display: "block",
                  maxWidth: "1300px",
                  zIndex: "100",
                  position: "sticky"
                }}
              >
                <div className="title" style={{ background: "#FFF" }}>
                  <PriceCalculation />
                </div>
              </div>
              {this.props.planType && this.props.planType.productId && parseInt(this.props.step) >= 2 ? (
                <WrappedRegistrationForm
                  planTerm={this.props.planTerm}
                  genderlist={this.state.genderList}
                  SaveStep={this.props.SaveStep}
                  SavePlanTerm={this.props.SavePlanTerm}
                  updatePrice={this.updatePrice}
                  SavePersonalDetails={this.props.SavePersonalDetails}
                  SaveBuyerEmail={this.props.SaveBuyerEmail}
                  handleBack={this.handleBack}
                  history={this.props.history}
                  productPricing={this.props.productPricing}
                  productInfo={this.props.productInfo}
                  planType={this.props.planType}
                  productPriceList={this.props.productPriceList}
                  personalDetails={this.props.personalDetails}
                  validateTravellerEmail={this.props.validateTravellerEmail}
                  emailCheckResponse={this.props.emailCheckResponse}
                  flushEmailCheckResponse={this.props.flushEmailCheckResponse}
                  userData={this.props.userData}
                  travellerInfo={this.props.travellerInfo}
                  getTravellerInfo={this.props.getTravellerInfo}
                  isLoggedIn={this.props.isLoggedIn}
                  countryTierInfo={this.props.countryTierInfo}
                  location={this.props.location}
                  b2b2cBaseUrl={this.props.b2b2cBaseUrl}
                  match={this.props.match}
                  resellerOrganizationInfo={this.props.resellerOrganizationInfo}
                  getAllCountries={this.props.getAllCountries}
                  allCountries={this.props.allCountries}
                  updateTravellerCount={this.updateTravellerCount}
                  //handleAnnualChnage={this.onChangeAnnual}
                  decoded_params={decodedParams}
                  currency={this.state.currency}
                  currencyRate={this.props.currencyRate}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </Beforeunload>
      </section>
    );
  }
}
PersonalInfo.propTypes = {
  getResellerProductPricingAndInfo: PropTypes.func,
  productPricing: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  SavePersonalDetails: PropTypes.func,
  SaveBuyerEmail: PropTypes.func,
  step: PropTypes.string,
  SavePlanTerm: PropTypes.func,
  planTerm: PropTypes.object,
  productInfo: PropTypes.array,
  planType: PropTypes.object,
  productPriceList: PropTypes.array,
  personalDetails: PropTypes.array,
  validateTravellerEmail: PropTypes.func,
  emailCheckResponse: PropTypes.object,
  flushEmailCheckResponse: PropTypes.func,
  getUserData: PropTypes.func,
  userData: PropTypes.object,
  getTravellerInfo: PropTypes.func,
  getAllCountries: PropTypes.func,
  travellerInfo: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  getAppliedPromo: PropTypes.func,
  couponInfo: PropTypes.object,
  getStep: PropTypes.func,
  b2b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2b2c,
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions, ...userActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PersonalInfo);
